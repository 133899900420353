import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { Dialog } from 'components/Dialog';
import { Input } from 'components/Input';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Select } from 'components/Select';
import { Textarea } from 'components/Textarea';
import { format } from 'date-fns';
import { Entity, type EntityContext } from 'domain-events';
import { usePhasingOptions, usePhasingRangeState } from 'pages/Products';
import { useLocalisation } from 'providers';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useServices } from 'services';
import styled from 'styled-components';
import {
  plpAttributeInheritTag,
  plpAttributeMandatoryTag,
  plpAttributeNewnessTag,
  plpAttributeNotesTag,
  plpAttributePhasingEndTag,
  plpAttributePhasingStartTag,
  plpAttributeRankingTag,
  plpAttributeSaveChangesTag,
  plpAttributeStoreGradesTag,
  plpAttributeVlpOrderTag,
  RANKING_OPTIONS,
  RankingMap,
  rem,
  themed,
  useIAM
} from 'utils';

import { Defect } from '@yourxx/support';
import { IsDraftValue, ProductListData, StoreGrade, UIProduct } from '@yourxx/types';

import { IAM } from '../IAM';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.m')};
  box-sizing: border-box;
`;
const Row = styled.div`
  display: flex;
  gap: ${themed('spacing.l')};

  > div {
    flex: 1;
  }

  textarea {
    background-color: ${themed('color.yellowLight')};
  }
`;
const Label = styled.div`
  display: flex;
  gap: ${themed('spacing.l')};

  span {
    flex: 1;
    font-weight: ${themed('font.weight.medium')};
  }

  label {
    display: flex;
    gap: ${themed('spacing.m')};
  }
`;
const VLPInput = styled(Input)`
  width: 100%;
  height: ${rem(38)};
  margin: ${themed('spacing.m')} 0 0;
`;
const StoreGrades = styled.div<{ $greyed?: boolean }>`
  display: flex;
  gap: ${themed('spacing.m')};
  margin: ${themed('spacing.m')} 0;

  button {
    background-color: ${({ $greyed }) => themed($greyed ? 'color.greyMid' : 'color.white')};
    font-weight: ${themed('font.weight.medium')};
    padding: ${themed('spacing.m')};
    gap: ${themed('spacing.m')};

    label {
      pointer-events: none;
    }
  }
`;
const StoreGradesInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${themed('font.size.s')};
  margin: ${themed('spacing.m')} 0;
  color: ${themed('color.red')};
`;
const NoteInfo = styled.span`
  display: block;
  font-size: ${themed('font.size.s')};
  margin: ${themed('spacing.m')} 0;
`;

type IsDraftValueKeys = keyof IsDraftValue;
type Attributes = {
  pc9Code?: string;
  firstOnFloor?: string | null;
  lastOnFloor?: string | null;
  newness?: string | null;
  mandatory?: boolean | null;
  ranking?: number | null;
  vlp?: number | null;
  storeGrades?: string[] | null;
  note?: string | null;
};

type PlaceholderValue = {
  newness?: string;
  note?: string | undefined;
  firstOnFloor?: string;
  lastOnFloor?: string;
  phasing: { start: string; end: string };
  ranking?: number;
  storeGrades?: string[];
  vlp?: number;
  mandatory?: boolean;
};

export const ProductAttributesModal = ({
  context,
  products,
  storeGradesOptions,
  newnessOptions,
  addProduct,
  onClose,
  onSuccess,
  iamPrefix
}: {
  context: EntityContext | undefined;
  products: UIProduct<ProductListData>[];
  storeGradesOptions?: StoreGrade[];
  newnessOptions?: string[];
  addProduct?: boolean;
  onClose: VoidFunction;
  onSuccess: VoidFunction;
  iamPrefix: 'line.product' | 'assortment.product';
}) => {
  const [str] = useLocalisation();
  const { productsService, toastService } = useServices();
  const { canNotUse, canUse } = useIAM();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [inheritedAttributes, setInheritedAttributes] = useState<IsDraftValue>(
    addProduct || products.length === 0 || products.length > 1
      ? {
          firstOnFloor: false,
          lastOnFloor: false,
          newness: false,
          mandatory: false,
          ranking: false,
          vlp: false
        }
      : {
          firstOnFloor: !products[0]?.isDraft?.firstOnFloor,
          lastOnFloor: !products[0]?.isDraft?.lastOnFloor,
          newness: !products[0]?.isDraft?.newness,
          mandatory: !products[0]?.isDraft?.mandatory,
          ranking: !products[0]?.isDraft?.ranking,
          vlp: !products[0]?.isDraft?.vlp
        }
  );

  const placeholderValues = useMemo(() => {
    return products.reduce(
      (acc: PlaceholderValue, p) => {
        acc.firstOnFloor = acc.firstOnFloor === p.firstOnFloor ? acc.firstOnFloor : undefined;
        acc.lastOnFloor = acc.lastOnFloor === p.lastOnFloor ? acc.lastOnFloor : undefined;
        acc.newness = acc.newness === p.newness ? acc.newness : undefined;
        acc.mandatory = acc.mandatory === p.mandatory ? acc.mandatory : undefined;
        acc.ranking = acc.ranking === p.ranking ? acc.ranking : undefined;
        acc.vlp = acc.vlp === p.vlp ? acc.vlp : undefined;
        acc.storeGrades = p.storeGrades?.filter(sg => acc.storeGrades?.includes(sg))?.length ? acc.storeGrades : [];
        acc.note = acc.note === p.note?.comment ? acc.note : undefined;
        acc.phasing.start = acc.phasing.start > p.phasing.start ? p.phasing.start : acc.phasing.start;
        acc.phasing.end = acc.phasing.end > p.phasing.end ? acc.phasing.end : p.phasing.end;
        return acc;
      },
      {
        firstOnFloor: products[0]?.firstOnFloor,
        lastOnFloor: products[0]?.lastOnFloor,
        newness: products[0]?.newness,
        mandatory: products[0]?.mandatory,
        ranking: products[0]?.ranking,
        vlp: products[0]?.vlp,
        storeGrades: products[0]?.storeGrades ?? [],
        note: products[0]?.note?.comment,
        phasing: {
          start: products[0]?.phasing.start,
          end: products[0]?.phasing.end
        }
      }
    );
  }, [products]);

  const [attributes, setAttributes] = useState<Attributes>({
    note: placeholderValues.note,
    storeGrades: placeholderValues.storeGrades
  });

  const { customerId, assortmentId: customerAssortmentId, lineId } = useParams();
  const customerOrLineId = customerId ?? lineId;
  const phasingOptions = usePhasingOptions(placeholderValues.phasing);
  const { phasingStart, phasingEnd, setStart, setEnd } = usePhasingRangeState(
    placeholderValues.firstOnFloor,
    placeholderValues.lastOnFloor
  );

  const handleUpdate = useCallback(() => {
    if (!context) throw new Defect('Missing context when adding/updating product(s).');
    setIsLoading(true);

    productsService[addProduct ? 'add' : 'update']({
      context,
      items: products.map(({ pc9 }) => ({ pc9 })),
      attributes: attributes as Record<string, any>
    })
      .then(() => toastService.send(<span>{str('PDP.product.updateSuccessful', { count: products.length })}</span>))
      .catch(_ => {
        toastService.send(<span>{str('PDP.product.updateFailed', { count: products.length })}</span>, 'error');
      })
      .finally(() => {
        if (!addProduct) {
          plpAttributeSaveChangesTag({
            pc9s: products.map(p => p.pc9),
            assortmentId: customerAssortmentId,
            customerId: customerOrLineId,
            updatedAttributes: JSON.stringify(attributes)
          });
        }
        setIsLoading(false);
        onSuccess();
      });
  }, [
    addProduct,
    context,
    onSuccess,
    products,
    productsService,
    str,
    toastService,
    plpAttributeSaveChangesTag,
    attributes,
    customerAssortmentId,
    customerOrLineId
  ]);

  const handleChecked = useCallback(
    (attr: IsDraftValueKeys, checked: boolean) => {
      plpAttributeInheritTag({
        inheritedAttribute: attr,
        pc9s: products.map(p => p.pc9),
        assortmentId: customerAssortmentId,
        customerId: customerOrLineId,
        selected: checked
      });
      setInheritedAttributes(prev => ({ ...prev, [attr]: checked }));
      if (checked) {
        setAttributes(prev => ({ ...prev, [attr]: null }));
      }
    },
    [setInheritedAttributes, plpAttributeInheritTag, products, customerAssortmentId, customerOrLineId, setAttributes]
  );

  const handleStoreGrades = (name: string) => {
    setAttributes(prev => {
      const storeGrades = prev?.storeGrades?.includes(name)
        ? prev.storeGrades.filter(sg => sg !== name)
        : prev?.storeGrades
          ? [...prev.storeGrades, name]
          : [name];
      plpAttributeStoreGradesTag({
        storeGrades,
        pc9s: products.map(p => p.pc9),
        assortmentId: customerAssortmentId,
        customerId: customerOrLineId
      });
      return { ...prev, storeGrades };
    });
  };

  if (!products.length) return null;
  return (
    <Dialog
      title={str(
        addProduct
          ? context?.entity === Entity.Line
            ? 'Line.addProduct.modalTitle'
            : 'Assortment.addProduct.modalTitle'
          : 'Line.editProduct.modalTitle'
      )}
      subtitle={str(addProduct ? 'Line.addProduct.infoPanel' : 'Line.editProduct.infoPanel', {
        count: products.length
      })}
      onClose={onClose}
      content={
        <ModalBody>
          <IAM action={'view.phasing'} prefix={iamPrefix}>
            <Row>
              <div>
                <Label>
                  <span>{str('Line.addProduct.phasingStart')}</span>
                  {!addProduct && (
                    <Checkbox
                      checked={inheritedAttributes.firstOnFloor}
                      onChange={(checked: boolean) => {
                        handleChecked('firstOnFloor', checked);
                      }}
                      label={str('Line.editProduct.inherit')}
                      disabled={canNotUse('edit.firstOnFloor', iamPrefix)}
                    />
                  )}
                </Label>
                <Select
                  enableOnClick={
                    canUse('edit.firstOnFloor', iamPrefix) ? () => handleChecked('firstOnFloor', false) : undefined
                  }
                  options={phasingOptions}
                  placeholder={placeholderValues.firstOnFloor && format(placeholderValues.firstOnFloor, 'MMM yy')}
                  isDisabled={canNotUse('edit.firstOnFloor', iamPrefix) || inheritedAttributes.firstOnFloor}
                  onChange={(option: any) => {
                    plpAttributePhasingStartTag({
                      value: option.label,
                      pc9s: products.map(p => p.pc9),
                      assortmentId: customerAssortmentId,
                      customerId: customerOrLineId
                    });
                    setStart(option.key);
                    setAttributes(prev => ({ ...prev, firstOnFloor: option.key }));
                  }}
                  value={phasingStart && { label: format(phasingStart, 'MMM yy') }}
                />
              </div>
              <div>
                <Label>
                  <span>{str('Line.addProduct.phasingEnd')}</span>
                  {!addProduct && (
                    <Checkbox
                      checked={inheritedAttributes.lastOnFloor}
                      onChange={(checked: boolean) => {
                        handleChecked('lastOnFloor', checked);
                      }}
                      label={str('Line.editProduct.inherit')}
                      disabled={canNotUse('edit.lastOnFloor', iamPrefix)}
                    />
                  )}
                </Label>
                <Select
                  enableOnClick={
                    canUse('edit.lastOnFloor', iamPrefix) ? () => handleChecked('lastOnFloor', false) : undefined
                  }
                  options={phasingOptions}
                  placeholder={placeholderValues.lastOnFloor && format(placeholderValues.lastOnFloor, 'MMM yy')}
                  isDisabled={canNotUse('edit.lastOnFloor', iamPrefix) || inheritedAttributes.lastOnFloor}
                  onChange={(option: any) => {
                    plpAttributePhasingEndTag({
                      value: option.label,
                      pc9s: products.map(p => p.pc9),
                      assortmentId: customerAssortmentId,
                      customerId: customerOrLineId
                    });
                    setEnd(option.key);
                    setAttributes(prev => ({ ...prev, lastOnFloor: option.key }));
                  }}
                  value={phasingEnd && { label: format(phasingEnd, 'MMM yy') }}
                />
              </div>
            </Row>
          </IAM>
          <Row>
            <IAM action={'view.newness'} prefix={iamPrefix}>
              <div>
                <Label>
                  <span>{str('Line.addProduct.newness')}</span>
                  {!addProduct && (
                    <Checkbox
                      checked={inheritedAttributes.newness}
                      onChange={(checked: boolean) => {
                        handleChecked('newness', checked);
                      }}
                      label={str('Line.editProduct.inherit')}
                      disabled={canNotUse('edit.newness', iamPrefix)}
                    />
                  )}
                </Label>
                <Select
                  enableOnClick={canUse('edit.newness', iamPrefix) ? () => handleChecked('newness', false) : undefined}
                  options={newnessOptions?.map(option => ({ key: option, label: option }))}
                  placeholder={placeholderValues.newness}
                  isDisabled={canNotUse('edit.newness', iamPrefix) || inheritedAttributes.newness}
                  onChange={(option: any) => {
                    plpAttributeNewnessTag({
                      value: option.label,
                      pc9s: products.map(p => p.pc9),
                      assortmentId: customerAssortmentId,
                      customerId: customerOrLineId
                    });
                    setAttributes(prev => ({ ...prev, newness: option.key }));
                  }}
                  value={attributes?.newness && { label: attributes?.newness }}
                />
              </div>
            </IAM>
            <IAM action={'view.mandatory'} prefix={iamPrefix}>
              <div>
                <Label>
                  <span>{str('Line.addProduct.mandatory')}</span>
                  {!addProduct && (
                    <Checkbox
                      checked={inheritedAttributes.mandatory}
                      onChange={(checked: boolean) => {
                        handleChecked('mandatory', checked);
                      }}
                      label={str('Line.editProduct.inherit')}
                      disabled={canNotUse('edit.mandatory', iamPrefix)}
                    />
                  )}
                </Label>
                <Select
                  enableOnClick={
                    canUse('edit.mandatory', iamPrefix) ? () => handleChecked('mandatory', false) : undefined
                  }
                  options={[
                    { key: true, label: str('Line.addProduct.mandatory.true') },
                    { key: false, label: str('Line.addProduct.mandatory.false') }
                  ]}
                  isDisabled={canNotUse('edit.mandatory', iamPrefix) && inheritedAttributes.mandatory}
                  placeholder={
                    typeof placeholderValues?.mandatory == 'boolean' &&
                    str(
                      placeholderValues.mandatory ? 'Line.addProduct.mandatory.true' : 'Line.addProduct.mandatory.false'
                    )
                  }
                  onChange={(option: any) => {
                    plpAttributeMandatoryTag({
                      value: option.label,
                      pc9s: products.map(p => p.pc9),
                      assortmentId: customerAssortmentId,
                      customerId: customerOrLineId
                    });
                    setAttributes(prev => ({ ...prev, mandatory: option.key }));
                  }}
                  value={
                    typeof attributes?.mandatory == 'boolean' && {
                      label: str(
                        attributes.mandatory ? 'Line.addProduct.mandatory.true' : 'Line.addProduct.mandatory.false'
                      )
                    }
                  }
                />
              </div>
            </IAM>
          </Row>
          <Row>
            <IAM action={'view.ranking'} prefix={iamPrefix}>
              <div>
                <Label>
                  <span>{str('Line.addProduct.ranking')}</span>
                  {!addProduct && (
                    <Checkbox
                      checked={inheritedAttributes.ranking}
                      onChange={(checked: boolean) => {
                        handleChecked('ranking', checked);
                      }}
                      label={str('Line.editProduct.inherit')}
                      disabled={canNotUse('edit.ranking', iamPrefix)}
                    />
                  )}
                </Label>
                <Select
                  enableOnClick={canUse('edit.ranking', iamPrefix) ? () => handleChecked('ranking', false) : undefined}
                  options={RANKING_OPTIONS.map(option => ({
                    key: option.value,
                    label: str(option.key)
                  }))}
                  placeholder={placeholderValues.ranking ? str(RankingMap[placeholderValues.ranking].key) : undefined}
                  isDisabled={canNotUse('edit.ranking', iamPrefix) || inheritedAttributes.ranking}
                  onChange={(option: any) => {
                    plpAttributeRankingTag({
                      value: option.label,
                      pc9s: products.map(p => p.pc9),
                      assortmentId: customerAssortmentId,
                      customerId: customerOrLineId
                    });
                    setAttributes(prev => ({ ...prev, ranking: option.key }));
                  }}
                  value={attributes?.ranking && { label: str(RankingMap[attributes.ranking].key) }}
                />
              </div>
            </IAM>
            <IAM action={'view.vlp'} prefix={iamPrefix}>
              <div>
                <Label>
                  <span>{str('Line.addProduct.vlp')}</span>
                  {!addProduct && (
                    <Checkbox
                      checked={inheritedAttributes.vlp}
                      onChange={(checked: boolean) => {
                        handleChecked('vlp', checked);
                      }}
                      label={str('Line.editProduct.inherit')}
                      disabled={canNotUse('edit.vlp', iamPrefix)}
                    />
                  )}
                </Label>
                <div onClick={canUse('edit.vlp', iamPrefix) ? () => handleChecked('vlp', false) : undefined}>
                  <VLPInput
                    type="number"
                    min="1"
                    max="9999"
                    placeholder={placeholderValues.vlp?.toString()}
                    disabled={canNotUse('edit.vlp', iamPrefix) || inheritedAttributes.vlp}
                    value={attributes.vlp ?? ''}
                    onChange={e => {
                      plpAttributeVlpOrderTag({
                        value: e.target.value,
                        pc9s: products.map(p => p.pc9),
                        assortmentId: customerAssortmentId,
                        customerId: customerOrLineId
                      });
                      setAttributes(prev => ({ ...prev, vlp: +Number(e.target.value) }));
                    }}
                  />
                </div>
              </div>
            </IAM>
          </Row>
          <IAM action={'edit.storeGrades'} prefix={iamPrefix}>
            {storeGradesOptions && (
              <Row>
                <div>
                  <Label>
                    <span>{str('Line.addProduct.storeGrades')}</span>
                  </Label>
                  {!addProduct &&
                    products.length > 1 &&
                    attributes.storeGrades &&
                    attributes.storeGrades.length > 0 && (
                      <StoreGradesInfo>
                        <span>{str('Line.editProduct.storeGradesNote')}</span>
                        {attributes.storeGrades?.length === storeGradesOptions.length && (
                          <span>{str('Line.editProduct.storeGradesAllAdded')}</span>
                        )}
                        {attributes.storeGrades.length === 0 && (
                          <span>{str('Line.editProduct.storeGradesAllRemoved')}</span>
                        )}
                        {attributes.storeGrades.length > 0 &&
                          attributes.storeGrades.length < storeGradesOptions.length && (
                            <span>
                              {str('Line.editProduct.storeGradesChanged', {
                                added: attributes.storeGrades.length,
                                removed: storeGradesOptions.length - attributes.storeGrades.length
                              })}
                            </span>
                          )}
                      </StoreGradesInfo>
                    )}
                  <StoreGrades $greyed={products.length > 1}>
                    {storeGradesOptions.map(s => (
                      <Button key={s.name} onClick={() => handleStoreGrades(s.name ?? '')}>
                        <span>{s.name}</span>
                        <Checkbox checked={Boolean(attributes?.storeGrades?.includes(s.name ?? ''))} />
                      </Button>
                    ))}
                  </StoreGrades>
                </div>
              </Row>
            )}
          </IAM>
          <Row>
            <IAM action={'view.note'} prefix={iamPrefix}>
              <div>
                <Label>
                  <span>{str('Line.addProduct.note')}</span>
                </Label>
                <NoteInfo>{str('Line.addProduct.noteInfo')}</NoteInfo>
                <Textarea
                  rows={6}
                  value={attributes.note ?? undefined}
                  onChange={e => {
                    plpAttributeNotesTag({
                      value: e.target.value,
                      pc9s: products.map(p => p.pc9),
                      assortmentId: customerAssortmentId,
                      customerId: customerOrLineId
                    });
                    setAttributes(prev => ({ ...prev, note: e?.target?.value?.length > 0 ? e.target.value : null }));
                  }}
                  disabled={canNotUse('edit.note', iamPrefix)}
                />
              </div>
            </IAM>
          </Row>
        </ModalBody>
      }
      cancel={{ label: str('general.cancel'), disabled: isLoading, handler: onClose }}
      confirm={{
        label: isLoading ? <LoadingSpinner /> : str(addProduct ? 'general.add' : 'general.save'),
        disabled: isLoading,
        handler: handleUpdate
      }}
    />
  );
};
