import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ensureSeasonParamIsValid, useEnsureRouteParams } from 'utils';

import { Customer } from './Customer';

/**
 * Components with *Route suffix are meant to be the middleman between routing and actual rendering components.
 * They get URL params, validate and pass them down as props to child components (the actual view components).
 */
export const CustomerRoute = () => {
  const [brand, customerId, season] = useEnsureRouteParams('brandName', 'customerId', 'season');

  const navigate = useNavigate();
  useEffect(() => {
    ensureSeasonParamIsValid(season, _ => navigate(`/${brand}/customers/${customerId}`, { replace: true }));
  }, [brand, customerId, navigate, season]);

  return <Customer brand={brand} customerId={customerId} season={season} />;
};
