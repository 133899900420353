import { AnimatePresence, motion, type Variants } from 'framer-motion';
import { useSlot } from 'providers';
import { cloneElement, type ReactElement, type ReactNode, useMemo, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { rem, themed } from 'utils';

import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  Placement,
  shift,
  useFloating,
  useFocus,
  useHover,
  useInteractions
} from '@floating-ui/react';

import { SlotId } from '../../SlotId';

const Container = styled(motion.div)`
  pointer-events: none;
  box-sizing: border-box;
  z-index: 9999;
  width: fit-content;
  border-radius: ${themed('borderRadius')};
  padding: ${rem(12)};
  background-color: ${themed('color.black')};
  color: ${themed('color.white')};
  box-shadow: ${themed('boxShadow')};
`;

const TextContent = styled.span`
  ${themed('typography.h4')};
  font-weight: ${themed('font.weight.regular')};
`;

const HINT_VARIANTS: Variants = {
  hidden: { opacity: 0, scale: 0.7 },
  visible: { opacity: 1, scale: 1 }
};

/**
 * For more configuration options check out: https://floating-ui.com/docs/react
 * @param content Hint content
 * @param on Hint placement. Use when you want to pass the placemen programmatically or as a prop from parents.
 * @param of Trigger element
 * @param left Convenience boolean flag for positioning
 * @param top
 * @param right
 * @param bottom
 */
export const Hint = ({
  children: content,
  on,
  of,
  left,
  top,
  right,
  bottom
}: {
  on?: Placement;
  left?: boolean;
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
  of: ReactElement;
  children: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    placement: on ?? (left ? 'left' : top ? 'top' : bottom ? 'bottom' : right ? 'right' : 'top'),
    open: isOpen,
    onOpenChange: setIsOpen,
    transform: false,
    middleware: [offset(10), shift({ padding: 12 }), flip(), arrow({ element: arrowRef, padding: 4 })],
    whileElementsMounted: autoUpdate
  });

  const hover = useHover(context, { delay: 0 });
  const focus = useFocus(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus]);

  // We're rendering outside where the trigger/reference element is rendered to avoid clipping due to overflows.
  useSlot(
    SlotId.Modals,
    useMemo(
      () => (
        <AnimatePresence mode="sync">
          {isOpen && (
            <Container
              ref={refs.setFloating}
              variants={HINT_VARIANTS}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{ delay: 0 }}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <FloatingArrow ref={arrowRef} context={context} tipRadius={2} width={10} height={5} />
              {typeof content === 'string' ? <TextContent>{content}</TextContent> : content}
            </Container>
          )}
        </AnimatePresence>
      ),
      [content, context, floatingStyles, getFloatingProps, isOpen, refs.setFloating]
    )
  );

  return cloneElement(of, { ref: refs.setReference, ...getReferenceProps() });
};
