import { format } from 'date-fns';

export const usePhasingOptions = ({ end, start }: { start: string; end: string }) => {
  if (!end || !start || start >= end) {
    return [];
  }
  const firstOnFloor: Date = new Date(start);
  const lastOnFloor: Date = new Date(end);
  const dates: { label: string; key: string }[] = [];
  while (firstOnFloor <= lastOnFloor) {
    dates.push({ label: format(firstOnFloor, 'MMM yy'), key: firstOnFloor.toISOString() });
    firstOnFloor.setMonth(firstOnFloor.getMonth() + 1);
  }
  dates.push({ label: format(lastOnFloor, 'MMM yy'), key: lastOnFloor.toISOString() });
  return dates;
};
