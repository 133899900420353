import { MotionOutlet } from 'components/MotionOutlet';
import { BrandLogo, SimpleNav, SlotId } from 'pages';
import { useLocalisation, useSlot } from 'providers';
import { useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ensureBrandParamIsValid, useEnsureRouteParams, useIAM } from 'utils';

export const BrandLayout = () => {
  const [str] = useLocalisation();
  const { canNotUse } = useIAM();

  const [brand] = useEnsureRouteParams('brandName');
  const navigate = useNavigate();
  useEffect(() => {
    ensureBrandParamIsValid(brand, _ => navigate(`/brands`, { replace: true }));
  }, [brand, navigate]);

  useSlot(
    SlotId.HeaderBrandLogo,
    useMemo(
      () => (
        <Link to="/brands">
          <BrandLogo brand={brand} />
        </Link>
      ),
      [brand]
    )
  );

  useSlot(
    SlotId.HeaderMainMenu,
    useMemo(
      () => (
        <SimpleNav
          id="main-menu"
          items={[
            { to: `/${brand}/customers`, label: str('Tab.header.customers.label') },
            { to: `/${brand}/lines`, label: str('Tab.header.lines.label'), isHidden: canNotUse('viewLines', 'header') }
          ]}
        />
      ),
      [brand, str]
    )
  );

  return <MotionOutlet animate />;
};
