import { Image } from 'components/Image';
import { HTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { css } from 'styled-components';
import { clamp, rem, themed, useDimensions } from 'utils';

import { useDrag } from '@use-gesture/react';

import { SliderImage } from './ImageSlider';

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  > div {
    width: 100%;
    height: 100%;
  }
`;
const StyledViewport = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;

  > div {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
`;
const ViewportChildrenWrapper = styled.div<{ $grabbable?: boolean }>`
  touch-action: none;
  width: 100%;
  height: 100%;
  ${({ $grabbable }) =>
    $grabbable &&
    css`
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    `};

  > img {
    vertical-align: top;
  }
`;
const Minimap = styled(animated.div)<{ $grabbable?: boolean; $offset?: MinimapOffset }>`
  box-shadow: ${themed('boxShadow')};
  display: flex;
  position: absolute;
  bottom: ${({ $offset }) => rem(12 + ($offset?.bottom ?? 0))};
  left: ${({ $offset }) => rem(12 + ($offset?.left ?? 0))};
  ${({ $grabbable }) =>
    $grabbable &&
    css`
      cursor: grab;
      touch-action: none;
      &:active {
        cursor: grabbing;
      }
    `};
`;
const MinimapLens = styled(animated.div)`
  position: absolute;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  pointer-events: none;
  border: ${rem(1)} solid ${themed('color.red')};
`;

const Viewport = ({
  children,
  contentProps,
  grabbable,
  scale = 1,
  x = 0.5,
  y = 0.5,
  ...props
}: HTMLAttributes<HTMLElement> & {
  children: ReactNode;
  contentProps?: HTMLAttributes<HTMLElement>;
  grabbable?: boolean;
  scale?: number;
  x?: number;
  y?: number;
}) => (
  <StyledViewport {...props}>
    <div style={{ transform: `scale(${scale}) translate(${(0.5 - x) * 100}%, ${(0.5 - y) * 100}%)` }}>
      <ViewportChildrenWrapper {...contentProps} $grabbable={grabbable}>
        {children}
      </ViewportChildrenWrapper>
    </div>
  </StyledViewport>
);

const scaleDimensions = (width: number, height: number, maxWidth: number, maxHeight: number) => {
  if (!width || !isFinite(width) || !height || !isFinite(height)) {
    return [0, 0];
  }
  const scale = Math.min(maxWidth / width, maxHeight / height);
  return [width * scale, height * scale];
};

const parseNumberOrPercentage = (value: number | string, percentageOf: number) =>
  typeof value === 'string' ? (parseInt(value, 10) / 100) * percentageOf : value;

const hasArea = (size: { width: number; height: number } | undefined): size is { width: number; height: number } =>
  Boolean(size && size.width && size.height);

const panBounds = (
  viewportSize: { width: number; height: number } | undefined,
  imageSize: { width: number; height: number } | undefined,
  scale: number
) => {
  if (!hasArea(viewportSize) || !hasArea(imageSize)) {
    return {
      x: { min: 0.5, max: 0.5 },
      y: { min: 0.5, max: 0.5 }
    };
  }

  const x = Math.min(viewportSize.width / (2 * imageSize.width * scale), 0.5);
  const y = Math.min(viewportSize.height / (2 * imageSize.height * scale), 0.5);
  return {
    x: { min: x, max: 1 - x },
    y: { min: y, max: 1 - y }
  };
};
const AnimatedViewport = animated(Viewport);

export type MinimapOffset = {
  bottom?: number;
  left?: number;
};

const ImageZoom = ({
  image,
  maxImageSize,
  scale = 1,
  minimapMaxWidth = 100,
  minimapMaxHeight = 100,
  offset
}: {
  image: SliderImage;
  maxImageSize: number;
  scale?: number;
  minimapMaxWidth?: number | string;
  minimapMaxHeight?: number | string;
  offset?: MinimapOffset;
}) => {
  const viewportRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const viewportSize = useDimensions(viewportRef);
  const imageSize = useDimensions(imageRef);

  const unscaledMinimapWidth = parseNumberOrPercentage(minimapMaxWidth, viewportSize?.width || 0);
  const unscaledMinimapHeight = parseNumberOrPercentage(minimapMaxHeight, viewportSize?.height || 0);
  const [minimapWidth, minimapHeight] = imageSize
    ? scaleDimensions(imageSize.width, imageSize.height, unscaledMinimapWidth, unscaledMinimapHeight)
    : [unscaledMinimapWidth, unscaledMinimapHeight];

  const [{ xy, scale_, minimapOpacity }, spring] = useSpring(() => ({
    xy: [0.5, 0.5],
    scale_: scale,
    minimapOpacity: scale === 1 ? 0 : 1
  }));

  const dragBind = useDrag(({ down, buttons, memo: [x, y] = xy.get(), movement: [dx, dy] }) => {
    if (!viewportSize || !imageSize) return;

    const isDragging = buttons & 1 || (down && buttons === 0);
    if (!isDragging) return;

    const s = scale_.get();
    const bounds = panBounds(viewportSize, imageSize, s);
    spring.start({
      xy: [
        clamp(x - dx / s / viewportSize.width, bounds.x.min, bounds.x.max),
        clamp(y - dy / s / viewportSize.height, bounds.y.min, bounds.y.max)
      ],
      immediate: true
    });

    return [x, y];
  });

  const minimap = useRef<HTMLDivElement | null>(null);
  const minimapBind = useDrag(({ xy: [x, y] }) => {
    if (!minimap.current || !viewportSize || !imageSize) return;

    const { left, top } = minimap.current.getBoundingClientRect();
    const bounds = panBounds(viewportSize, imageSize, scale_.get());
    spring.start({
      xy: [
        clamp((x - left) / minimapWidth, bounds.x.min, bounds.x.max),
        clamp((y - top) / minimapHeight, bounds.y.min, bounds.y.max)
      ]
    });
  });

  useEffect(() => {
    if (!viewportSize || !imageSize) return;

    const bounds = panBounds(viewportSize, imageSize, scale);
    spring.start({
      xy: [clamp(xy.goal[0], bounds.x.min, bounds.x.max), clamp(xy.goal[1], bounds.y.min, bounds.y.max)],
      scale_: scale,
      minimapOpacity: scale === 1 ? 0 : 1
    });
  }, [scale, spring, xy, viewportSize, imageSize]);

  const panEnabled = scale !== 1;

  return (
    <Wrapper>
      <div ref={viewportRef}>
        <AnimatedViewport
          contentProps={{ ...dragBind() }}
          grabbable={panEnabled}
          scale={scale_}
          x={xy.to(x => x)}
          y={xy.to((_, y) => y)}
        >
          <Image
            ref={imageRef}
            width={image.size || maxImageSize}
            height={image.size || maxImageSize}
            src={`${image.url}&width=${image.size || maxImageSize}&height=${image.size || maxImageSize}`}
            alt={image.imageType}
            draggable={false}
          />
        </AnimatedViewport>
      </div>
      <Minimap
        ref={minimap}
        style={{ width: minimapWidth, height: minimapHeight, opacity: minimapOpacity }}
        $grabbable={panEnabled}
        $offset={offset}
        {...minimapBind()}
      >
        <Image
          loading="eager"
          src={`${image.url}&width=${minimapWidth}&height=${minimapHeight}`}
          alt="minimap"
          draggable={false}
        />
        {viewportSize && imageSize && (
          <MinimapLens
            style={{
              left: xy.to(x => x * minimapWidth),
              top: xy.to((_, y) => y * minimapHeight),
              width: scale_.to(s => (minimapWidth / s) * (viewportSize.width / imageSize.width) || 0),
              height: scale_.to(s => (minimapHeight / s) * (viewportSize.height / imageSize.height) || 0)
            }}
          />
        )}
      </Minimap>
    </Wrapper>
  );
};

export default ImageZoom;
