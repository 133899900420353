import { MotionOutlet } from 'components/MotionOutlet';
import { ErrorBoundary } from 'react-error-boundary';
import { styled } from 'styled-components';

import { ErrorBoundaryFallback } from '../CommonLayout';
import { AdminPanelAccessSetup } from './AdminPanelAccessSetup';
import { AWSRumSetup } from './AWSRumSetup';
import { GoToV1LinkSetup } from './GoToV1LinkSetup/GoToV1LinkSetup';
import { Header } from './Header';
import { ProfilePanelSetup } from './ProfilePanelSetup';
import { SearchSetup } from './SearchSetup';

const GlobalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const GlobalLayout = () => (
  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={console.warn}>
    <GlobalWrapper>
      <Header />
      <>
        <AWSRumSetup />
        <GoToV1LinkSetup />
        <AdminPanelAccessSetup />
        <ProfilePanelSetup />
        <SearchSetup />
      </>
      <MotionOutlet />
    </GlobalWrapper>
  </ErrorBoundary>
);
