import { useLocalisation, useOrders } from 'providers';
import { Suspense } from 'react';
import { Await } from 'react-router-dom';
import { relativePath } from 'utils';

import { ShowAsyncErrorAndGoBack, useAddBreadcrumb, ViewTransition } from '../../CommonLayout';
import { OrderSizingSkeleton } from './OrderSizingSkeleton';
import { SizingView } from './SizingView';

export const OrderSizing = ({ orderId, pc9 }: { orderId: string; pc9: string }) => {
  const [str] = useLocalisation();
  useAddBreadcrumb(str('Order.sizing.breadcrumbLabel'), 3, relativePath('..'));
  const { loadOrderSizing } = useOrders();

  return (
    <Suspense fallback={<ViewTransition children={<OrderSizingSkeleton />} />}>
      <Await
        errorElement={<ShowAsyncErrorAndGoBack />}
        resolve={loadOrderSizing(orderId, pc9)}
        children={data => <ViewTransition children={<SizingView data={data} />} />}
        // children={_data => <ViewTransition children={<OrderSizingSkeleton />} />}
      />
    </Suspense>
  );
};
