import { SpinnerAlt } from 'assets/icons';
import { AnimatePresence, motion, type Variants } from 'framer-motion';
import type { SlotWrapperProps } from 'providers';
import React, { forwardRef, type MouseEvent, type ReactNode, type Ref } from 'react';
import { Link } from 'react-router-dom';
import { css, styled } from 'styled-components';
import { rem, themed } from 'utils';

import { Hint } from './Hint';

type HintPosition = 'top' | 'right' | 'bottom' | 'left';

export interface ToolbarItemProps {
  position?: number;
  icon: ReactNode;
  label?: ReactNode;
  hint?:
    | string
    | {
        text: string | ReactNode;
        position?: HintPosition;
        // TODO: Not yet implemented
        offset?: [number, number?];
      };
  to?: string;
  // Use in conjunction with `to`. When `external` is set to `true` a regular `<a />` tag will be used, instead of `<Link />`
  external?: boolean;
  onClick?: VoidFunction;
  isActive?: boolean;
  isVisible?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  badgeContent?: string;
}

const ContainerWrapper = styled.div`
  display: flex;
  align-self: stretch;
  align-items: stretch;
  gap: ${themed('spacing.s')};

  &:empty {
    display: none;
  }
`;

// FIXME: Can I find a fix for the weird rendering behaviour when I leave animations in?
//   Otherwise I'll have to drop the idea.
//   Look into the custom tooling framer-motion offers, maybe the out-of-the box utilities don't work for our use-case.
const Container = ({ children, 'data-slot-id': dataSlotId }: { children: ReactNode; 'data-slot-id'?: string }) => {
  return (
    <ContainerWrapper data-slot-id={dataSlotId}>
      {/*<AnimatePresence>{children}</AnimatePresence>*/}
      {children}
    </ContainerWrapper>
  );
};

const ItemWrapperEl = styled(motion.div)`
  display: flex;
  align-items: center;
  align-self: stretch;
  // FIXME: Do we need this, it's causing some issues...
  //max-height: ${themed('spacing.xxl')};

  &:empty {
    display: none;
  }
`;

// const ITEM_WRAPPER_VARIANTS: Variants = {
//   hidden: { opacity: 0, scale: 0.7 },
//   visible: { opacity: 1, scale: 1 }
// };

const ItemWrapper = ({
  children,
  'data-slot-content-id': slotContentId
}: SlotWrapperProps & { children: ReactNode }) => {
  return (
    <ItemWrapperEl
      key={slotContentId}
      data-slot-content-id={slotContentId}
      // initial="hidden"
      // animate="visible"
      // exit="hidden"
      // variants={ITEM_WRAPPER_VARIANTS}
    >
      {children}
    </ItemWrapperEl>
  );
};

const clickableStyling = css<{ $isActive?: boolean }>`
  position: relative;
  display: flex;
  gap: ${themed('spacing.s')};
  align-self: stretch;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: 0;
  border-radius: ${themed('borderRadius')};
  padding: ${themed('spacing.s')} ${themed('spacing.m')};

  ${({ $isActive }) =>
    $isActive
      ? css`
          color: ${themed('color.white')};
          background-color: ${themed('color.black')};
        `
      : css`
          color: ${themed('color.black')};
          background-color: transparent;

          @media (hover: hover) {
            &:hover {
              background-color: ${themed('color.greyLight')};
            }
          }
        `}
  svg {
    max-width: ${themed('spacing.xl')};
    max-height: ${themed('spacing.xl')};

    > * {
      stroke: currentColor;
    }
  }
`;

const disabledStyling = css`
  cursor: not-allowed;
  opacity: 0.3;
`;

const ToolbarItemButton = styled.button<{ $isActive?: boolean }>`
  ${clickableStyling};

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    ${disabledStyling};
  }
`;

const ToolbarItemLink = styled(Link)<{ $isActive?: boolean; $isDisabled?: boolean }>`
  ${clickableStyling};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'unset')};
  ${({ $isDisabled }) => ($isDisabled ? disabledStyling : undefined)};
`;

const BADGE_VARIANTS: Variants = {
  hidden: { opacity: 0, scale: 0.7, x: '50%', y: '-50%' },
  visible: { opacity: 1, scale: 1, x: '50%', y: '-50%' }
};

const Badge = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: ${themed('borderRadius')};
  padding: ${rem(1, 4, 0)};
  background-color: ${themed('color.greyDark')};
  color: ${themed('color.white')};
  ${themed('typography.h5')};
  transform: translate3d(50%, -50%, 0);
`;

const ClickableElement = forwardRef<
  HTMLElement,
  Pick<ToolbarItemProps, 'to' | 'onClick' | 'isActive' | 'isDisabled' | 'external'> & { children: ReactNode }
>(({ children, to, onClick, isActive, isDisabled, external }, ref) => {
  if (to) {
    const props = {
      to,
      onClick: (event: MouseEvent) => {
        if (isDisabled) return event.preventDefault();
        onClick?.();
      },
      $isActive: isActive,
      $isDisabled: isDisabled,
      children
    };

    if (external) return <a ref={ref as Ref<HTMLAnchorElement>} {...props} data-is-active={isActive} />;
    return <ToolbarItemLink ref={ref as Ref<HTMLAnchorElement>} {...props} data-is-active={isActive} />;
  }

  return (
    <ToolbarItemButton
      ref={ref as Ref<HTMLButtonElement>}
      onClick={onClick}
      $isActive={isActive}
      disabled={isDisabled}
      children={children}
      data-is-active={isActive}
    />
  );
});

const Item = ({
  icon,
  to,
  onClick,
  hint,
  label,
  isActive,
  isVisible = true,
  isDisabled,
  isLoading,
  badgeContent
}: ToolbarItemProps) => {
  if (!isVisible) return null;

  const content = (
    <>
      {icon}
      {label ? ` ${label}` : null}
    </>
  );

  const item = (
    <ClickableElement to={to} onClick={onClick} isActive={isActive} isDisabled={isDisabled}>
      <AnimatePresence>
        {badgeContent && (
          <Badge
            key="badge"
            children={badgeContent}
            variants={BADGE_VARIANTS}
            initial="hidden"
            animate="visible"
            exit="hidden"
          />
        )}
        {isLoading ? <SpinnerAlt key="spinner" /> : React.cloneElement(content, { key: 'content' })}
      </AnimatePresence>
    </ClickableElement>
  );

  if (!hint) return item;

  return (
    <Hint on={typeof hint !== 'string' ? hint.position : undefined} of={item}>
      {typeof hint === 'string' ? hint : hint.text}
    </Hint>
  );
};

export const Toolbar = {
  Container,
  ItemWrapper,
  Item
};
