import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import { css, styled } from 'styled-components';
import { rem, themed } from 'utils';

import { Hint } from './Hint';

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.xl')};
`;

const Underline = styled(motion.div)`
  position: absolute;
  bottom: ${themed('spacing.s')};
  left: 0;
  right: 0;
  height: ${rem(1)};
  width: 100%;
  background-color: ${themed('color.red')};
`;

const StyledNavLink = styled(NavLink)<{ $disabled?: boolean }>`
  position: relative;
  user-select: none;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  min-height: ${themed('spacing.xxl')};
  color: ${themed('color.black')};
  text-decoration: none;
  transition-property: width;
  will-change: width;
  transition-timing-function: ${themed('transition.timing')};
  transition-duration: ${themed('transition.duration')};

  &.active {
    font-weight: ${themed('font.weight.bold')};
  }

  ${({ $disabled }) =>
    !$disabled
      ? css`
          @media (hover: hover) {
            &:not(.active):hover {
              color: ${themed('color.grey')};
            }
          }
        `
      : css`
          cursor: not-allowed;
          color: ${themed('color.greyMid')};
        `}
`;

// TODO: Expand with isDisabled, isVisible, isLoading?
export interface SimpleNavItemProps {
  to: string;
  state?: any;
  label: string;
  hint?: string;
  isDisabled?: boolean;
  isHidden?: boolean;
}

export const SimpleNav = ({
  id,
  items,
  activeOnRouteEnd
}: {
  id: string;
  items: SimpleNavItemProps[];
  activeOnRouteEnd?: boolean;
}) => (
  <StyledNav>
    {items.map(item => {
      if (item.isHidden) return null;

      const link = (
        <StyledNavLink
          key={item.to}
          to={item.to}
          state={item.state}
          end={activeOnRouteEnd}
          aria-disabled={item.isDisabled}
          $disabled={item.isDisabled}
          onClick={event => {
            if (item.isDisabled) event.preventDefault();
          }}
        >
          {({ isActive, isPending }) => (
            <>
              {item.label}
              {(isActive || isPending) && <Underline layoutId={`${id}-underline`} transition={{ duration: 0.2 }} />}
            </>
          )}
        </StyledNavLink>
      );

      if (!item.hint) return link;

      return <Hint key={item.to} top of={link} children={item.hint} />;
    })}
  </StyledNav>
);
