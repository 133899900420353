import { useEffect } from 'react';
import styled from 'styled-components';
import { configsByType, EnvironmentType, getEnvConfig, isEnvironmentType, themed } from 'utils';

const Label = styled.div<{ $type: string }>`
  margin-left: ${themed('spacing.l')};
  padding: ${themed('spacing.s')} ${themed('spacing.m')};
  pointer-events: none;
  border-radius: ${themed('spacing.l')};
  color: ${({ $type }) => themed(`color.${['staging', 'preprod'].includes($type) ? 'white' : 'black'}`)};
  background-color: ${({ $type }) =>
    themed(
      `color.${
        ['staging', 'preprod'].includes($type)
          ? 'greyDark'
          : ['dev', 'development'].includes($type)
            ? 'blue'
            : ['qa', 'testing'].includes($type)
              ? 'yellowLight'
              : 'green'
      }`
    )};
`;

export const EnvLabel = ({ className }: { className?: string }) => {
  const type = getEnvConfig().ENV_INDICATOR_TYPE as EnvironmentType;

  useEffect(() => {
    if (type && !isEnvironmentType(type)) {
      console.warn(`EnvironmentIndicator: Unknown environment type '${type}'`);
    }
  }, [type]);

  const config = configsByType[type];
  if (!config || !type || (type && !isEnvironmentType(type))) {
    return null;
  }
  return (
    <Label className={className} $type={type}>
      {config.text}
    </Label>
  );
};
