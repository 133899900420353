import { IAM } from 'components';
import { ShowAsyncErrorAndGoBack, ViewTransition } from 'pages';
import { useSetupCustomerView } from 'pages/Customer';
import { OrderCapture } from 'pages/Landing/Assortments/OrderCapture';
import { type CustomerOrders, useOrders } from 'providers';
import { Suspense } from 'react';
import { Await, Route, Routes } from 'react-router-dom';
import { styled } from 'styled-components';
import { parseSeasonParam, useEnsureRouteParams } from 'utils';

import { CustomerOrderRoute } from '../CustomerOrder';
import { CustomerOrdersIndexSkeleton } from './CustomerOrdersIndexSkeleton';

export const CustomerOrdersIndex = (_props: { brand: string; customerId: string }) => {
  return (
    <Routes>
      <Route index Component={IndexRoute} />
      <Route path=":orderId/*" Component={CustomerOrderRoute} />
    </Routes>
  );
};

const IndexRoute = () => {
  const [customerId, season] = useEnsureRouteParams('customerId', 'season');
  return <Index customerId={customerId} season={season} />;
};

const Index = ({ customerId, season }: { customerId: string; season: string }) => {
  const { loadCustomerOrders } = useOrders();
  const maybeSeason = parseSeasonParam(season);
  useSetupCustomerView({ customerId, season: maybeSeason, currentView: 'orders' });

  return (
    <Suspense fallback={<ViewTransition children={<CustomerOrdersIndexSkeleton />} />}>
      <Await
        errorElement={<ShowAsyncErrorAndGoBack />}
        resolve={loadCustomerOrders()}
        children={data => <ViewTransition children={<View season={season} data={data} />} />}
      />
    </Suspense>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const View = ({
  season: _season,
  data: { brand: _brand, customerId: _customerId, orders: _allOrders }
}: {
  season?: string;
  data: CustomerOrders;
}) => {
  // const _orders = useMemo(() => allOrders, [allOrders]);

  return (
    <IAM action={`orders.view`}>
      <Container>
        <OrderCapture
          // assortments={filteredAssortments}
          // // TODO: remove dupes on the BE
          // locations={[...new Map(customerData?.locations?.shipTo.map(l => [l.key, l])).values()]}
          assortments={[]}
          locations={[]}
        />
      </Container>
    </IAM>
  );
};
