import { CsvEvent, KafkaEvent, S3Event } from '@yourxx/types/src/data-ingestion';

export const isObject = (value?: unknown): value is object => {
  return !!(value && typeof value === 'object' && !isDate(value));
};

export const isArray = (value?: unknown): value is Array<any> => {
  return !!(value && Array.isArray(value));
};

export const isDate = (value?: unknown): value is Date => {
  return !!(value && value instanceof Date);
};

export const isNumeric = (value?: unknown): value is number => {
  if (typeof value === 'string') {
    return !isNaN(Number(value));
  }
  return typeof value === 'number';
};

export const isString = (value?: unknown): value is string => {
  return typeof value === 'string';
};

export const isValidDate = (value?: Date | null): value is Date => {
  if (isNil(value)) {
    return false;
  }
  return !isNaN(value.valueOf());
};

export function isNil(value: any): value is undefined | null {
  return value === undefined || value === null || value === void 0;
}

const emailRegex = /^[\S.]+@([\w-]+\.)+[\w-]{2,4}/g;
const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i;

export const isRegexMatch = (regex: RegExp, value?: string): boolean => (value ? new RegExp(regex).test(value) : false);
export const isEmail = (value?: string): boolean => isRegexMatch(emailRegex, value);
export const isUuid = (value?: string): boolean => isRegexMatch(uuidRegex, value);
export const isKeyOf = (value: string | undefined, keys: string[]): boolean =>
  value ? keys.some(k => k.toLowerCase() === value.toLowerCase()) : false;
export const isRejected = (result: PromiseSettledResult<unknown>): result is PromiseRejectedResult =>
  result.status === 'rejected';
export const isFulfilled = <T = any>(result: PromiseSettledResult<unknown>): result is PromiseFulfilledResult<T> =>
  result.status === 'fulfilled';
type DynamoDBError = { errorType: string; name: string; errorMessage: string };
export const isDynamoError = (error: any): error is DynamoDBError =>
  error.errorType && error.errorType === 'DynamoDBServiceException';

export const isCsvEvent = (value: unknown): value is CsvEvent => (value as CsvEvent)['Items'] !== undefined;
export const isKafkaEvent = (value: unknown): value is KafkaEvent[] =>
  Array.isArray(value) &&
  value.length > 0 &&
  (value[0] as KafkaEvent).eventSource &&
  (value[0] as KafkaEvent).eventSource === 'aws:kafka';

export const isS3Event = (value: unknown): value is S3Event =>
  (value as S3Event).source && (value as S3Event).source === 'aws.s3';

export const isPc9Code = (value?: string) => value && /^[\dA-Z]{5}-[\dA-Z]{4}$/i.test(value);
