import { Plus } from 'assets/icons';
import { IAM } from 'components';
import { GhostButton, SlotId } from 'pages';
import { useLocalisation, useSlot } from 'providers';
import { useCallback, useMemo, useState } from 'react';
import { OrderStatus } from 'services';
import styled from 'styled-components';
import { themed } from 'utils';

import { CustomerAssortmentArray, CustomerShipTo } from '@yourxx/types';

import { OrderCreateEditModal, OrderDeleteModal, OrderFooter, OrderList, type OrderModalType } from '.';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${themed('color.offWhite')};

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    margin-left: -${themed('spacing.xl')};
    margin-right: -${themed('spacing.xl')};
  }

  > div:first-of-type {
    flex: 1;
  }
`;
const NoContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${themed('spacing.xxxl')};
`;

export type Order = {
  finalAssortment: CustomerAssortmentArray;
  locations: CustomerShipTo[];
  orderId: string;
  orderName: string;
  created: string;
  products: number;
  units: number;
  price: number;
  currency: string;
  status: OrderStatus;
};

export const OrderCapture = ({
  assortments,
  locations
}: {
  assortments: CustomerAssortmentArray[];
  locations?: CustomerShipTo[];
}) => {
  const randomBetween = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;
  const randomOrder = (orderData?: Partial<Order>): Order => ({
    orderId: `${randomBetween(1000, 9999)}`,
    finalAssortment: orderData?.finalAssortment ?? assortments[randomBetween(0, assortments.length - 1)],
    locations: orderData?.locations ?? locations?.slice(0, randomBetween(1, 10)) ?? [],
    orderName: orderData?.orderName ?? `000000${randomBetween(1, 100).toString().padStart(2, '0')}`,
    created: orderData?.created ?? `2024-${randomBetween(1, 10)}-${randomBetween(1, 30)}`,
    products: orderData?.products ?? randomBetween(100, 1000),
    units: orderData?.units ?? randomBetween(10, 100),
    price: orderData?.price ?? randomBetween(10000, 50000),
    currency: orderData?.currency ?? 'GBP',
    status: orderData?.status ?? Math.random() < 0.5 ? OrderStatus.InProgress : OrderStatus.Submitted
  });

  const [str] = useLocalisation();
  const [orders, setOrders] = useState<Order[]>(() => Array.from({ length: 20 }, () => randomOrder()));
  const [currentOrder, setCurrentOrder] = useState<Order | undefined>();
  const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
  const [showOrderDelete, setOrderDeleteVisibility] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orderModalType, setOrderModalType] = useState<OrderModalType>();

  useSlot(
    SlotId.PageToolbarRight,
    useMemo(
      () => (
        <GhostButton onClick={() => setOrderModalType('create')}>
          <Plus />
          {str('Order.createOrder')}
        </GhostButton>
      ),
      [str]
    )
  );

  const handlePlaceOrders = useCallback(({ orders }: { orders: Order[] }) => {
    console.log('ordering ' + orders.map(o => o.orderName));
  }, []);

  const handleCreateOrder = useCallback(
    ({ order, orderPerLocation }: { order: Order; orderPerLocation?: boolean }) => {
      setIsLoading(true);
      setTimeout(() => {
        const createOrderForLocation = (location: CustomerShipTo) => ({
          ...randomOrder({
            ...order,
            created: new Date().toISOString().split('T')[0],
            locations: [location]
          })
        });
        const createOrder = () => ({
          ...randomOrder({
            ...order,
            created: new Date().toISOString().split('T')[0],
            locations: orderPerLocation ? [] : order.locations
          })
        });

        const ordersToCreate = orderPerLocation
          ? order.locations.length > 1
            ? order.locations.map(createOrderForLocation)
            : [createOrderForLocation(order.locations[0])]
          : [createOrder()];

        setIsLoading(false);
        setOrders(prev => [...prev, ...ordersToCreate]);
        setOrderModalType(undefined);
      }, 1000);
    },
    [setOrders, orderModalType, setOrderModalType, setIsLoading]
  );

  const handleEditOrder = useCallback(
    ({ order }: { order: Order }) => {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setOrders(prev =>
          prev.map(prev =>
            prev.orderId === order.orderId
              ? {
                  ...prev,
                  finalAssortment: order.finalAssortment,
                  orderName: order.orderName,
                  locations: order.locations
                }
              : prev
          )
        );
        setOrderModalType(undefined);
      }, 1000);
    },
    [setOrders, setOrderModalType, setIsLoading]
  );

  const handleDeleteOrders = useCallback(
    ({ orders }: { orders: Order[] }) => {
      setIsLoading(true);
      setTimeout(() => {
        setOrders(prev => prev.filter(order => !orders.some(o => o.orderId === order.orderId)));
        setIsLoading(false);
        setOrderDeleteVisibility(false);
        setOrderModalType(undefined);
        if (currentOrder) {
          setCurrentOrder(undefined);
          setSelectedOrders(prev =>
            prev.includes(currentOrder) ? prev.filter(p => p.orderId !== currentOrder.orderId) : prev
          );
        } else {
          setSelectedOrders([]);
        }
      }, 1000);
    },
    [setOrders, setIsLoading, setOrderDeleteVisibility, currentOrder, setCurrentOrder, setSelectedOrders]
  );

  return (
    <Wrapper>
      {!orders?.length ? (
        <NoContent>{str('Order.noOrders')}</NoContent>
      ) : (
        <>
          <OrderList
            orders={orders}
            selectedOrders={selectedOrders}
            setSelectedOrders={setSelectedOrders}
            setCurrentOrder={setCurrentOrder}
            setOrderModalType={setOrderModalType}
          />
          <OrderFooter
            orders={orders}
            selectedOrders={selectedOrders.length}
            onDeselectAll={() => setSelectedOrders([])}
            viewSummary={() => console.log('view summary')}
            placeOrders={() => handlePlaceOrders({ orders: selectedOrders })}
            deleteOrders={() => setOrderDeleteVisibility(true)}
          />
          <IAM action={`orders.edit`}>
            {orderModalType && (
              <OrderCreateEditModal
                type={orderModalType}
                order={currentOrder}
                assortments={assortments}
                locations={locations}
                onCreate={data => handleCreateOrder(data)}
                onEdit={data => handleEditOrder(data)}
                onDelete={currentOrder ? () => setOrderDeleteVisibility(true) : undefined}
                onClose={() => {
                  setCurrentOrder(undefined);
                  setOrderModalType(undefined);
                }}
                isLoading={isLoading}
              />
            )}
          </IAM>
          <IAM action={`orders.delete`}>
            {showOrderDelete && (
              <OrderDeleteModal
                orders={currentOrder ? [currentOrder] : selectedOrders}
                onConfirm={() => handleDeleteOrders({ orders: currentOrder ? [currentOrder] : selectedOrders })}
                onClose={() => setOrderDeleteVisibility(false)}
                isLoading={isLoading}
              />
            )}
          </IAM>
        </>
      )}
    </Wrapper>
  );
};
