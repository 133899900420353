import { css, DefaultTheme as StyledDefaultTheme, keyframes } from 'styled-components';
import { rem } from 'utils';

const color = {
  red: '#C41230', // rgb(196, 18, 48)
  yellow: '#FFD80C',
  orange: '#FFBD3C',
  yellowLight: '#f4f0c9',
  blue: '#14AAFF',
  green: '#17C608',
  black: '#000000',
  white: '#FFFFFF',
  offWhite: '#EFEFEF',
  offWhiteLight: '#F7F7F7',
  grey: '#7F7F7F',
  greyDark: '#393838',
  greyMid: '#CCCCCC',
  greyLight: '#E7E7E7',
  transparentRed: 'rgba(196, 18, 48, 0.3)',
  transparentBlack: 'rgba(0, 0, 0, 0.9)',
  transparentMidBlack: 'rgba(0, 0, 0, 0.5)',
  transparentLightBlack: 'rgba(0, 0, 0, 0.3)'
};

const flexColumn = css`
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  min-height: 0;
`;

const spacing = {
  xs: rem(2),
  s: rem(4),
  m: rem(8),
  l: rem(16),
  xl: rem(24),
  xxl: rem(32),
  xxxl: rem(48)
};

const breakpoints = {
  xxl: '1920px',
  xl: '1680px',
  l: '1400px',
  m: '1280px',
  s: '960p',
  xs: '720px'
};

// TODO: Agree with the design team on a limited set of configurations to be reused across all UI elements!
const font = {
  family: "'InterVariable', 'Inter', sans-serif",
  defaultSize: '14px', // This should match the font-size on <html> for rem scaling to work properly
  defaultWeight: 400,
  size: {
    xs: rem(10),
    s: rem(12),
    m: rem(14),
    l: rem(18),
    xl: rem(24),
    xxl: rem(32)
  },
  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900
  }
};

const typography = {
  h1: css`
    font-size: ${font.size.xl};
    font-weight: ${font.weight.bold};
    line-height: ${rem(26)};
    letter-spacing: 0.04em; // 4%
  `,
  h2: css`
    font-size: ${font.size.l};
    font-weight: ${font.weight.medium};
    line-height: ${rem(15)};
    letter-spacing: 0.04em;
  `,
  h3: css`
    font-size: ${font.size.m};
    font-weight: ${font.weight.medium};
    line-height: ${rem(15)};
    letter-spacing: 0.04em;
  `,
  h4: css`
    font-size: ${font.size.s};
    font-weight: ${font.weight.medium};
    line-height: ${rem(15)};
    letter-spacing: 0.04em;
  `,
  h5: css`
    font-size: ${font.size.xs};
    font-weight: ${font.weight.medium};
    line-height: ${rem(15)};
    letter-spacing: 0.04em;
  `
};

const zIndex = {
  100: 100,
  200: 200,
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
  900: 900,
  1000: 1000
};

const button = {
  paddingX: spacing.s,
  paddingY: spacing.m,
  gap: spacing.s,
  variant: {
    primary: {
      backgroundColor: color.black,
      color: color.white
    },
    secondary: {
      backgroundColor: color.white,
      color: color.black
    }
  }
};

const input = {
  padding: spacing.m,
  borderColor: color.black,
  background: color.white,
  focusOutline: `2px solid ${color.greyMid}`
};

const dropdown = {
  minWidth: rem(200),
  background: color.white
};

const borderRadius = spacing.s;
const boxShadow = `0px 2px 5px 0px ${color.transparentLightBlack}`;

const transition = {
  duration: '200ms',
  // Ease-out-quad
  timing: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)'
};

const animation = {
  fadeIn: keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
  `,
  slideDown: keyframes`
    from {
      transform: translateY(-1vh);
    }
    to {
      transform: translateY(0);
    }
  `,
  zoomOut: keyframes`
    0% {
      transform: scale(0);
    }
    80% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  `,
  spin: keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
};

export const DefaultTheme: StyledDefaultTheme = {
  color,
  font,
  borderRadius,
  spacing,
  button,
  input,
  dropdown,
  boxShadow,
  zIndex,
  transition,
  animation,
  typography,
  breakpoints,
  flexColumn
};
