import { asErrorMessage } from './asErrorMessage';

export const ensureParamValidity =
  (isValid: (value: string) => void, paramName = 'param') =>
  (value: string, onError: (error: Error) => void) => {
    try {
      isValid(value);
    } catch (error) {
      onError(new RangeError(`Unexpected ${paramName} value: ${asErrorMessage(error)}`));
    }
  };
