import { useLocalisation } from 'providers';
import { styled } from 'styled-components';
import { rem, themed } from 'utils';

import type { ProductSizingTotals } from '../types';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${themed('spacing.m')};
  border-radius: ${themed('borderRadius')};
  padding: ${themed('spacing.m')} ${themed('spacing.xl')};
  background-color: ${themed('color.offWhiteLight')};
  ${themed('typography.h3')};
  font-weight: ${themed('font.weight.light')};
`;

const Totals = styled.h4`
  margin: 0;
  font-weight: ${themed('font.weight.semiBold')};
`;
const Metrics = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: ${themed('spacing.xxl')};
`;
const Metric = styled.li`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.m')};

  span {
    display: flex;
    align-items: center;
    gap: ${themed('spacing.m')};
  }

  span:first-child::after {
    content: '';
    display: inline-flex;
    width: ${rem(1)};
    height: ${rem(15)};
    background-color: ${themed('color.greyMid')};
  }

  span:last-child {
    color: #505050; // FIXME: Outside of theme
  }

  strong {
    font-weight: ${themed('font.weight.bold')};
  }
`;

export const SizingTotals = (props: ProductSizingTotals) => {
  const [str] = useLocalisation();
  return (
    <Container>
      <Totals>{str('Order.metrics.totals')}</Totals>
      <Metrics>
        <Metric>
          <span>
            {str('Order.metrics.units')} <strong>{props.units}</strong>
          </span>
          <span>
            {str('Order.metrics.total')} <strong>{props.unitsPercentOfTotal}</strong>
          </span>
        </Metric>
        <Metric>
          <span>
            {str('Order.metrics.price')} <strong>{props.price}</strong>
          </span>
          <span>
            {str('Order.metrics.total')} <strong>{props.pricePercentOfTotal}</strong>
          </span>
        </Metric>
      </Metrics>
    </Container>
  );
};
