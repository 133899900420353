import { asErrorMessage } from './asErrorMessage';
import { isValidSeasonParam } from './ensureSeasonParamIsValid';

export const parseSeasonParam = (season: string | undefined) => {
  if (typeof season === 'undefined') return season;

  try {
    isValidSeasonParam(season);
    return season === 'season' ? undefined : season;
  } catch (error) {
    console.warn(new RangeError(`Unexpected season param value: ${asErrorMessage(error)}`));
    return undefined;
  }
};
