import { useLocalisation } from 'providers';
import { css, styled } from 'styled-components';
import { below, rem, themed } from 'utils';

import type { MonthRollup } from '../types';

const borderRadius = 'spacing.m';
const Container = styled.ul`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: ${themed('spacing.m')};
  list-style: none;
  margin: 0 0 ${rem(12)};
  padding: 0;

  ${below(
    'l',
    css`
      grid-template-columns: repeat(6, 1fr);
    `
  )};

  ${below(
    'm',
    css`
      grid-template-columns: repeat(4, 1fr);
    `
  )};
`;
const SizingMonth = styled.li<{ $isSelected?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &::before {
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    top: ${({ $isSelected }) => rem($isSelected ? -1 : 0)};
    left: ${({ $isSelected }) => rem($isSelected ? -1 : 0)};
    z-index: 10;
    border: solid ${({ $isSelected }) => rem($isSelected ? 2 : 1)};
    border-color: ${({ $isSelected }) => themed(`color.${$isSelected ? 'black' : 'greyMid'}`)};
    border-radius: ${themed(borderRadius)};
    width: calc(100% + ${({ $isSelected }) => rem($isSelected ? 2 : 0)});
    height: calc(100% + ${({ $isSelected }) => rem($isSelected ? 2 : 0)});
    transition-property: border-color;
    transition-timing-function: ${themed('transition.timing')};
    transition-duration: ${themed('transition.duration')};
  }

  > * {
    position: relative;
    z-index: 5;
  }

  @media (hover: hover) {
    &:hover::before {
      border-color: ${themed('color.black')};
    }
  }
`;
const UnavailableSizingMonth = styled(SizingMonth)`
  cursor: not-allowed;
  border-radius: ${themed('spacing.m')};
  background-color: ${themed('color.greyLight')};
  color: ${themed('color.greyMid')};

  @media (hover: hover) {
    &:hover::before {
      border-color: ${themed('color.greyMid')};
    }
  }
`;
const MonthNotAvailable = styled.span`
  flex-grow: 1;
  text-align: center;
  color: ${themed('color.greyMid')};
`;
const MonthTitle = styled.h6`
  margin: 0 0 ${rem(2)};
  padding: ${rem(3, 0, 1)};
  border-top-right-radius: ${themed('spacing.m')};
  border-top-left-radius: ${themed('spacing.m')};
  ${themed('typography.h4')};
  font-weight: ${themed('font.weight.black')};
  text-transform: uppercase;
  text-align: center;
  background-color: ${themed('color.greyLight')};
`;
const Metric = styled.div`
  display: flex;
  gap: ${rem(6)};
  padding: 0 ${themed('spacing.s')};
  color: ${themed('color.grey')};
  ${themed('typography.h5')};
  font-weight: ${themed('font.weight.regular')};
`;
const Key = styled.span`
  flex-basis: 50%;
`;
const Value = styled.span`
  flex-basis: 50%;
  display: flex;
  justify-content: flex-end;
  font-variant: tabular-nums;
`;
const TotalPrice = styled.span`
  flex-basis: 100%;
  margin: ${rem(2)} 0 0;
  ${themed('typography.h5')};
  font-weight: ${themed('font.weight.bold')};
  text-align: center;
`;

export const SizingMonths = ({
  monthRollUps,
  onSelect,
  isSelected
}: {
  monthRollUps: readonly MonthRollup[];
  isSelected: (id: string) => boolean;
  onSelect: (id: string) => void;
}) => {
  const [str] = useLocalisation();
  return (
    <Container>
      {monthRollUps.map(({ id, monthName, unitsCount, totalPrice, isNotAvailable }) => {
        if (isNotAvailable)
          return (
            <UnavailableSizingMonth key={id}>
              <MonthTitle>{monthName}</MonthTitle>
              <Metric>
                <MonthNotAvailable>{str('general.notAvailableAlt')}</MonthNotAvailable>
              </Metric>
            </UnavailableSizingMonth>
          );

        return (
          <SizingMonth key={id} $isSelected={isSelected(id)} onClick={() => onSelect(id)}>
            <MonthTitle>{monthName}</MonthTitle>
            <Metric>
              <Key>{str('Order.metrics.units')}</Key>
              <Value>{unitsCount}</Value>
            </Metric>
            <TotalPrice>{totalPrice}</TotalPrice>
          </SizingMonth>
        );
      })}
    </Container>
  );
};
