import { Copy, Paste } from 'assets/icons';
import { Dialog } from 'components/Dialog';
import { Image } from 'components/Image';
import { useLocalisation, useSlot } from 'providers';
import { useCallback, useMemo, useState } from 'react';
import { styled } from 'styled-components';
import { rem, themed } from 'utils';

import { SlotId } from '../../../SlotId';
import { useAddOrderToolbarControl } from '../../OrderLayout';
import type { ProductDisplayInfo } from '../types';

const PasteIconFix = styled.span`
  svg * {
    stroke: none;
  }

  [data-is-active='true'] & svg path {
    fill: ${themed('color.white')};
  }
`;

export const CopySizingModalSetup = ({ product }: { product: ProductDisplayInfo }) => {
  const [str] = useLocalisation();
  const [isCopying, setIsCopying] = useState(false);
  const toggleCopying = useCallback(() => setIsCopying(p => !p), []);

  useAddOrderToolbarControl(
    useMemo(
      () => ({
        icon: <Copy />,
        label: str('Order.sizing.copyTo').toUpperCase(),
        hint: str('Order.sizing.copyToHint'),
        isActive: isCopying,
        onClick: toggleCopying
      }),
      [isCopying, str, toggleCopying]
    ),
    0
  );

  useSlot(
    SlotId.Modals,
    isCopying && (
      <Dialog
        title={str('Order.sizing.copyToTitle')}
        onClose={toggleCopying}
        content={<CopyTo source={product} />}
        cancel={{
          handler: toggleCopying,
          label: str('general.cancel')
        }}
        confirm={{
          handler: toggleCopying,
          label: str('general.copy'),
          disabled: false
        }}
      />
    )
  );

  const [isPasting, setIsPasting] = useState(false);
  const togglePasting = useCallback(() => setIsPasting(p => !p), []);

  useAddOrderToolbarControl(
    useMemo(
      () => ({
        icon: (
          <PasteIconFix>
            <Paste />
          </PasteIconFix>
        ),
        label: str('Order.sizing.pasteFrom').toUpperCase(),
        hint: str('Order.sizing.pasteFromHint'),
        isActive: isPasting,
        onClick: togglePasting
      }),
      [isPasting, str, togglePasting]
    ),
    1
  );

  useSlot(
    SlotId.Modals,
    isPasting && (
      <Dialog
        title={str('Order.sizing.pasteFromTitle')}
        onClose={togglePasting}
        content={<PasteFrom target={product} />}
        cancel={{
          handler: togglePasting,
          label: str('general.cancel')
        }}
        confirm={{
          handler: togglePasting,
          label: str('general.paste'),
          disabled: false
        }}
      />
    )
  );

  return null;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(12)};
  min-width: ${rem(648)};

  section {
    display: flex;
    flex-direction: column;
    gap: ${rem(12)};
  }

  section > h3 {
    margin: 0;
    color: ${themed('color.grey')};
    ${themed('typography.h3')};
    font-weight: ${themed('font.weight.bold')};
    text-transform: uppercase;
  }
`;

const ProductCard = styled.div`
  display: flex;
  gap: ${rem(12)};

  h4,
  h5 {
    margin: 0;
  }

  h4 {
    ${themed('typography.h4')};
    font-weight: ${themed('font.weight.semiBold')};
  }

  h5 {
    color: ${themed('color.grey')};
    ${themed('typography.h5')};
    font-weight: ${themed('font.weight.regular')};
  }
`;
const Thumbnail = styled.div`
  width: ${rem(90)};
  height: ${rem(90)};
`;
const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${themed('spacing.s')};
`;

const TargetProductsGridPlaceholder = styled.div`
  display: flex;
  background-color: ${themed('color.offWhite')};
  justify-content: center;
  align-items: center;
  min-height: ${rem(384)};
  ${themed('typography.h4')};
`;

const CopyTo = ({ source }: { source: ProductDisplayInfo }) => {
  const [str] = useLocalisation();
  return (
    <Container>
      <section>
        <h3>{str('general.from')}</h3>
        <Product {...source} />
      </section>
      <section>
        <h3>{str('general.to')}</h3>
        <TargetProductsGridPlaceholder>Target Products Grid Placeholder</TargetProductsGridPlaceholder>
      </section>
    </Container>
  );
};

const PasteFrom = ({ target }: { target: ProductDisplayInfo }) => {
  const [str] = useLocalisation();
  return (
    <Container>
      <section>
        <h3>{str('general.from')}</h3>
        <TargetProductsGridPlaceholder>Target Products Grid Placeholder</TargetProductsGridPlaceholder>
      </section>
      <section>
        <h3>{str('general.to')}</h3>
        <Product {...target} />
      </section>
    </Container>
  );
};

const Product = ({ imageUrl, name, pc9 }: ProductDisplayInfo) => (
  <ProductCard>
    <Thumbnail>
      <Image width={90} src={imageUrl} />
    </Thumbnail>
    <ProductInfo>
      <h4>{name}</h4>
      <h5>{pc9}</h5>
    </ProductInfo>
  </ProductCard>
);
