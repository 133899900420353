import { Copy, Paste } from 'assets/icons';
import { formatDate } from 'date-fns';
import { OrderSizing as IOrderSizing, useLocalisation, useSlot } from 'providers';
import { useEffect, useMemo, useState } from 'react';
import { styled } from 'styled-components';
import { findById, formatPercentage, formatPrice, relativePath, rem, themed } from 'utils';

import { toggleArrayValue } from '@yourxx/support';

import { Toolbar } from '../../CommonLayout';
import { SlotId } from '../../SlotId';
import {
  BackToProductsListLink,
  CopySizingModalSetup,
  ProductInformation,
  ProductNavigation,
  ProductSlideshow,
  SizingMonths,
  SizingTotals
} from './components';
import type { MonthRollup, ProductDisplayInfo, ProductSizingTotals, SizingProductInformation } from './types';

const Container = styled.main`
  box-sizing: border-box;
  overflow-y: auto;
  position: relative;
  display: flex;
  gap: ${rem(40)};
  padding: 0 0 ${themed('spacing.xxxl')};
  clip-path: inset(0 0 0 0);
  min-height: 0;

  > aside,
  > div {
    flex-grow: 1;
  }
`;
const productSizingWidth = '65%';
const borderRadius = 'spacing.m';
const Details = styled.aside`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${themed('spacing.m')};
  width: calc(100% - ${productSizingWidth});
`;
const Sizing = styled.div`
  display: flex;
  flex-direction: column;
  width: ${productSizingWidth};
`;
const SizingGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.s')};
`;
const SelectedMonths = styled.div`
  border-top-right-radius: ${themed(borderRadius)};
  border-top-left-radius: ${themed(borderRadius)};
  padding: ${themed('spacing.s')} ${themed('spacing.m')};
  background-color: ${themed('color.greyLight')};
  color: ${themed('color.black')};
  ${themed('typography.h3')};
  font-weight: ${themed('font.weight.black')};
  text-align: center;
  text-transform: uppercase;
`;
const SizingGridToolbar = styled.div``;
const SizeGridPlaceholder = styled.div`
  display: flex;
  background-color: ${themed('color.offWhite')};
  justify-content: center;
  align-items: center;
  min-height: ${rem(384)};
  ${themed('typography.h4')};
`;
const ProductName = styled.h2`
  margin: 0;
  ${themed('typography.h1')};
  font-size: ${rem(16)}; // Outside of theme
  line-height: 1;
`;
const PC9 = styled.span`
  display: flex;
  margin-top: ${themed('spacing.s')};
  color: ${themed('color.grey')};
  ${themed('typography.h4')};
  font-weight: ${themed('font.weight.regular')};
  font-variant: tabular-nums;
`;

const PasteIconFix = styled.span`
  svg * {
    stroke: none;
  }

  [data-is-active='true'] & svg path {
    fill: ${themed('color.white')};
  }
`;

export const SizingView = ({ data }: { data: IOrderSizing }) => {
  const [str] = useLocalisation();

  useSlot(
    SlotId.FooterLeft,
    <>
      {str('Order.metrics.units')} 2000 {str('general.of')} 10000
    </>
  );

  // TODO: Get from higher-order state
  const monthRollUps = useMemo(() => {
    const startingMonth = Math.floor(Math.random() * 12);

    return new Array(12).fill(true).map<MonthRollup>((_, i) => {
      const month = (startingMonth + i) % 12 || 12;
      const year = 2024 + Math.floor((startingMonth + i - 1) / 12);

      return {
        id: `${year}-${String(month).padStart(2, '0')}`,
        monthName: formatDate(new Date(year, month - 1, 1), 'MMM'),
        unitsCount: (1000).toLocaleString(),
        isNotAvailable: Math.random() > 0.8,
        totalPrice: formatPrice('GBP', 0)
      };
    });
  }, [data.pc9]);

  const [selectedMonths, setSelectedMonths] = useState<string[]>([monthRollUps[0].id]);
  useEffect(() => setSelectedMonths([monthRollUps[0].id]), [monthRollUps]);
  const selectedMonthsLabel = useMemo(
    () =>
      selectedMonths
        .map(id => findById(id)(monthRollUps))
        .map(month => month?.monthName)
        .filter(Boolean)
        .join(', ') || '—',
    [monthRollUps, selectedMonths]
  );

  // TODO: Get information from API data
  const totals = useMemo<ProductSizingTotals>(
    () => ({
      units: (150).toLocaleString(),
      unitsPercentOfTotal: formatPercentage(0.15),
      price: formatPrice('GBP', 1600),
      pricePercentOfTotal: formatPercentage(0.05)
    }),
    []
  );

  // TODO: Get information from API data
  const productSlideshow = useMemo(
    () => ({
      slides: ['video', 'main', 'gallery1', 'gallery2', 'gallery3', 'gallery4'].map((id, i) => ({
        thumbnailUrl: `https://yourxx.levistrauss.com/api/v2/image/${data.pc9}?season_name=202501&region=NEU&fallback_img=true&image_format=jpg&image_type=${id}&width=96&height=96`,
        url: `https://yourxx.levistrauss.com/api/v2/image/${data.pc9}?season_name=202501&region=NEU&fallback_img=true&image_format=jpg&image_type=${id}&width=1024&height=1024`,
        isVideo: i === 0
      }))
    }),
    [data.pc9]
  );

  // TODO: Get information from API data
  const productInformation = useMemo<SizingProductInformation>(
    () => ({
      pricing: {
        tier: 'TIER 2',
        wsp: formatPrice('GBP', 1600),
        rrp: formatPrice('GBP', 3995)
      }
    }),
    []
  );

  // TODO: Get information from API data
  const product = useMemo<ProductDisplayInfo>(
    () => ({
      name: `Product name for ${data.pc9}`,
      pc9: data.pc9,
      imageUrl: `https://yourxx.levistrauss.com/api/v2/image/${data.pc9}?season_name=202501&region=NEU&fallback_img=true&image_format=jpg&image_type=${'main'}&width=180&height=180`
    }),
    [data.pc9]
  );

  return (
    <Container>
      <BackToProductsListLink />
      <CopySizingModalSetup product={product} />
      <Details>
        {/*TODO: Consider adding thumbnails to next/prev tooltips*/}
        <ProductNavigation
          previous={{ name: 'Previous product name', pc9: '001IW-0001', url: relativePath('../001IW-0001') }}
          next={{ name: 'Next product name', pc9: '001SB-0001', url: relativePath('../001SB-0001') }}
        >
          <ProductName>{product.name}</ProductName>
          <PC9>{product.pc9}</PC9>
        </ProductNavigation>
        <ProductSlideshow {...productSlideshow} />
        <ProductInformation {...productInformation} />
      </Details>
      <Sizing>
        <SizingTotals {...totals} />
        <SizingMonths
          monthRollUps={monthRollUps}
          isSelected={id => selectedMonths.includes(id)}
          onSelect={id => setSelectedMonths(toggleArrayValue([id], selectedMonths).sort())}
        />
        <SizingGridWrapper>
          <SelectedMonths>{selectedMonthsLabel}</SelectedMonths>
          <SizingGridToolbar>
            <Toolbar.Container>
              <Toolbar.Item icon={<Copy />} label={str('general.copy')} isDisabled={!selectedMonths.length} />
              <Toolbar.Item
                icon={
                  <PasteIconFix>
                    <Paste />
                  </PasteIconFix>
                }
                label={str('general.paste')}
                isDisabled={true}
              />
            </Toolbar.Container>
          </SizingGridToolbar>
          <SizeGridPlaceholder>Size Grid Placeholder</SizeGridPlaceholder>
        </SizingGridWrapper>
      </Sizing>
    </Container>
  );
};
