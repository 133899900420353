import { MotionOutlet } from 'components/MotionOutlet';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ensureSeasonParamIsValid, useEnsureRouteParams } from 'utils';

export const LinesRoute = () => {
  const [brand, season] = useEnsureRouteParams('brandName', 'season');

  const navigate = useNavigate();
  useEffect(() => {
    ensureSeasonParamIsValid(season, _ => navigate(`/${brand}/lines`, { replace: true }));
  }, [brand, navigate, season]);

  return <MotionOutlet />;
};
