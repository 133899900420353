import { useLocalisation } from 'providers';
import { css, styled } from 'styled-components';
import { below, rem, themed } from 'utils';

import type { SizingProductInformation } from '../types';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.l')};
  margin: ${themed('spacing.l')} 0 0;
  padding: ${rem(10)};
  border: solid ${rem(1)} ${themed('color.greyMid')};
  border-radius: ${rem(12)};
`;

const Heading = styled.h4`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.m')};
  margin: 0 0 ${rem(10)};
  ${themed('typography.h3')};
  font-weight: ${themed('font.weight.semiBold')};
`;

const Stat = styled.div`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.m')};
  ${themed('typography.h3')};
  font-weight: ${themed('font.weight.light')};

  & + & {
    margin-top: ${themed('spacing.s')};
  }

  &::before {
    content: attr(data-label);
    flex-basis: 25%;

    ${below(
      'l',
      css`
        flex-basis: 40%;
      `
    )}

    ${below(
      'm',
      css`
        flex-basis: 50%;
      `
    )}
  }

  &::after {
    content: attr(data-value);
    font-variant: tabular-nums;
  }
`;

const PriceTier = styled.span`
  display: inline-flex;
  padding: ${rem(1, 3, 0, 3)};
  border-radius: ${themed('borderRadius')};
  background-color: ${themed('color.grey')};
  color: ${themed('color.white')};
  ${themed('typography.h5')};
  font-weight: ${themed('font.weight.medium')};
  text-transform: uppercase;
`;

export const ProductInformation = ({ pricing }: SizingProductInformation) => {
  const [str] = useLocalisation();
  return (
    <Container>
      <section>
        <Heading>
          {str('PDP.stats.pricing')} {pricing.tier && <PriceTier>{pricing.tier}</PriceTier>}
        </Heading>
        <Stat data-label={str('PDP.stats.wholesale')} data-value={pricing.wsp} />
        <Stat data-label={str('PDP.stats.retail')} data-value={pricing.rrp} />
      </section>
      <section>
        <Heading>Misc</Heading>
        <Stat data-label={'Label'} data-value={'Value'} />
      </section>
    </Container>
  );
};
