import { isAfter } from 'date-fns';
import { useLocalisation } from 'providers';
import { useCallback, useState } from 'react';

export const usePhasingRangeState = (initialStart?: string, initialEnd?: string) => {
  const [str] = useLocalisation();

  if (initialStart && initialEnd && isAfter(initialStart, initialEnd)) {
    throw Error(str('PDP.labels.phasingRuleHint'));
  }
  const [phasingStart, setPhasingStart] = useState<string | undefined>(initialStart);
  const [phasingEnd, setPhasingEnd] = useState<string | undefined>(initialEnd);
  const setStart = useCallback(
    (start: string) => {
      setPhasingStart(start);
      if (phasingEnd && isAfter(start, phasingEnd)) {
        setPhasingEnd(start);
      }
    },
    [isAfter, setPhasingStart, setPhasingEnd, phasingEnd]
  );
  const setEnd = useCallback(
    (end: string) => {
      setPhasingEnd(end);
      if (phasingStart && isAfter(phasingStart, end)) {
        setPhasingStart(end);
      }
    },
    [isAfter, setPhasingStart, setPhasingEnd, phasingStart]
  );

  return { phasingStart, phasingEnd, setStart, setEnd };
};
