import ReactSelect, { Props } from 'react-select';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const StyledSelect = styled(ReactSelect)`
  padding: 0 !important;
  outline: none !important;
  margin: ${themed('spacing.m')} 0;
  border-radius: ${themed('spacing.s')} !important;
  will-change: box-shadow, border;
  transition:
    box-shadow 0.2s ease-out,
    border 0.2s ease-out;

  @media (hover: hover) {
    &:hover {
      box-shadow: ${themed('boxShadow')};
    }
  }

  .Select__control {
    font-size: ${themed('font.size.m')};
    background-color: ${themed('color.white')};
    color: ${themed('color.black')};
    border: solid ${rem(1)} transparent;
    padding-right: ${themed('spacing.s')};
    appearance: none;
    outline: none;
    will-change: box-shadow, border;
    transition:
      box-shadow 0.2s ease-out,
      border 0.2s ease-out;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        border: solid ${rem(1)} transparent;
        outline: none;
        box-shadow: none;
      }
    }

    &.Select__control--is-focused {
      border: solid ${rem(1)} ${themed('color.black')};
      outline: none;
      box-shadow: none;
    }

    .Select__indicator-separator {
      display: none;
    }

    .Select__indicator {
      will-change: transform;
      transition: transform 0.2s ease-out;
      padding: ${themed('spacing.s')};
    }

    &.Select__control--menu-is-open .Select__dropdown-indicator {
      transform: rotate(180deg);
    }
  }

  .Select__option {
    background-color: ${themed('color.white')};
    will-change: background-color;
    transition: background-color 0.2s ease-out;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        background-color: ${themed('color.greyLight')};
      }
    }

    &.Select__option--is-selected {
      color: ${themed('color.black')};
      background-color: ${themed('color.greyMid')};
    }
  }

  .Select__placeholder {
    color: ${themed('color.grey')};
  }
`;

export type SelectOption = {
  value: string;
  label: string;
};

export const Select = ({ enableOnClick, ...props }: Props & { enableOnClick?: () => void }) => {
  if (enableOnClick) {
    return (
      <div onClick={() => enableOnClick()}>
        <StyledSelect classNamePrefix="Select" {...props} getOptionValue={(option: any) => option.label} />
      </div>
    );
  }
  return <StyledSelect classNamePrefix="Select" {...props} getOptionValue={(option: any) => option.label} />;
};
