import { Region } from '../enum';

export enum SapOrderType {
  ZOR = 'ZOR',
  ZOR1 = 'ZOR1'
}

export enum SapOrderReason {
  ZP1 = 'ZP1',
  ZP2 = 'ZP2',
  ZP3 = 'ZP3',
  ZP4 = 'ZP4',
  ZP5 = 'ZP5'
}

export enum SapPOType {
  B2B = 'B2B',
  BCOM = 'BCOM',
  YXX = 'YXX'
}

export enum SapDistChannel {
  WHOLESALE = '10',
  RETAIL = '20',
  INTER_COMPANY = '30'
}

export type OrderRegion = Region.AMA | Region.LSA | Region.LSE;

export const InterfaceIdByRegion: Record<string, Record<OrderRegion, string>> = {
  qa: {
    [Region.LSA]: 'TODO',
    [Region.LSE]: 'IT75483',
    [Region.AMA]: 'TODO'
  },
  'pre-prod': {
    [Region.LSA]: 'TODO',
    [Region.LSE]: 'IT75483',
    [Region.AMA]: 'TODO'
  },
  prod: {
    [Region.LSA]: 'TODO',
    [Region.LSE]: 'IT75483',
    [Region.AMA]: 'TODO'
  },
  dev: {
    [Region.LSA]: 'TODO',
    [Region.LSE]: 'IT75483',
    [Region.AMA]: 'TODO'
  }
};

export const OrderTypeByRegion: Record<OrderRegion, SapOrderType> = {
  [Region.LSA]: SapOrderType.ZOR1,
  [Region.LSE]: SapOrderType.ZOR,
  [Region.AMA]: SapOrderType.ZOR
};

export const cmRegions: Record<string, OrderRegion> = {
  LSE: Region.LSE,
  LSA: Region.LSA,
  AMA: Region.AMA
} as const;

export const regionMap: Record<string, OrderRegion> = {
  AMA: Region.AMA,
  ANZ: Region.AMA,
  CAN: Region.LSA,
  EAS: Region.AMA,
  GCH: Region.AMA,
  GCR: Region.AMA,
  JPN: Region.AMA,
  KOR: Region.AMA,
  LAT: Region.LSA,
  LSA: Region.LSA,
  LSE: Region.LSE,
  MEA: Region.LSE,
  NEU: Region.AMA,
  SEA: Region.LSA,
  SEU: Region.LSE,
  SOA: Region.AMA,
  USA: Region.LSA,
  USC: Region.LSA,
  ZAF: Region.LSE,
  CNF: Region.LSA
} as const;
