import { ChevronLeft, ChevronRight } from 'assets/icons';
import { type ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { rem, themed } from 'utils';

import type { Nullable } from '@yourxx/support';

import { Hint } from '../../../CommonLayout';

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${themed('spacing.m')};

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${rem(38)};
    height: ${rem(36)};
    border-radius: ${themed('spacing.s')};

    @media (hover: hover) {
      &:hover {
        background-color: ${themed('color.offWhite')};
      }
    }
  }
`;
const Children = styled.div`
  flex-grow: 1;
`;

export const ProductNavigation = <T extends { name: string; pc9: string; url: string }>({
  children,
  previous,
  next
}: {
  children: ReactNode;
  previous: Nullable<T>;
  next: Nullable<T>;
}) => (
  <Nav>
    {/* TODO: Disabled state */}
    <Hint
      top
      of={
        <Link to={previous?.url ?? '.'}>
          <ChevronLeft />
        </Link>
      }
    >
      {previous && <TooltipContent name={previous.name} pc9={previous.pc9} />}
    </Hint>
    <Children>{children}</Children>
    <Hint
      top
      of={
        <Link to={next?.url ?? '.'}>
          <ChevronRight />
        </Link>
      }
    >
      {next && <TooltipContent name={next.name} pc9={next.pc9} />}
    </Hint>
  </Nav>
);

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${themed('spacing.xs')};

  h5,
  h6 {
    margin: 0;
    text-align: left;
    white-space: nowrap;
  }

  h5 {
    ${themed('typography.h4')};
    font-weight: ${themed('font.weight.bold')};
  }

  h6 {
    ${themed('typography.h4')};
    font-weight: ${themed('font.weight.regular')};
    font-variant: tabular-nums;
  }
`;

const TooltipContent = ({ name, pc9 }: { name: string; pc9: string }) => (
  <TooltipContainer>
    <h5>{name}</h5>
    <h6>{pc9}</h6>
  </TooltipContainer>
);
