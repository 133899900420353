import { ColDef, GetContextMenuItemsParams } from 'ag-grid-enterprise';
import { Copy, Edit } from 'assets/icons';
import { AgListView, Button, Checkbox } from 'components';
import { IAM } from 'components/IAM';
import { format } from 'date-fns';
import { StatusCapsule } from 'pages/Orders/components';
import { useLocalisation } from 'providers';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { formatPrice, themed } from 'utils';

import type { CustomerShipTo } from '@yourxx/types';

import type { Order, OrderModalType } from '.';

const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${themed('spacing.s')};

  button {
    padding: ${themed('spacing.s')};
  }
`;
const OrderLink = styled(Link)`
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  color: ${themed('color.black')};
  ${themed('typography.h4')};
  font-variant: tabular-nums;

  @media (hover: hover) {
    &:hover {
      color: ${themed('color.grey')};
    }
  }
`;

export const OrderList = ({
  orders,
  selectedOrders,
  setSelectedOrders,
  setCurrentOrder,
  setOrderModalType
}: {
  orders: Order[];
  selectedOrders: Order[];
  setSelectedOrders: Dispatch<SetStateAction<Order[]>>;
  setCurrentOrder: Dispatch<SetStateAction<Order | undefined>>;
  setOrderModalType: Dispatch<SetStateAction<OrderModalType | undefined>>;
}) => {
  const [str] = useLocalisation();

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'noExport',
        headerComponent: () => (
          <Checkbox
            id="headerCheckbox"
            checked={orders.length === selectedOrders.length}
            onChange={() => setSelectedOrders(orders.length === selectedOrders.length ? [] : orders)}
            extraPadding
          />
        ),
        cellRenderer: ({ data }: { data: any }) => (
          <span style={{ display: 'flex' }}>
            <Checkbox
              id={data.order}
              checked={selectedOrders.includes(data)}
              onChange={() =>
                setSelectedOrders(prev =>
                  prev.includes(data) ? prev.filter(p => p.orderId !== data.orderId) : [...prev, data]
                )
              }
              extraPadding
            />
          </span>
        ),
        width: 60,
        resizable: false,
        pinned: 'left'
      },
      {
        field: 'orderName',
        headerName: str('Landing.order.table.orderName'),
        cellRenderer: ({ value }: { value: any }) => <OrderLink to={value}>{value}</OrderLink>,
        flex: 2,
        minWidth: 200
      },
      {
        field: 'finalAssortment.assortmentName',
        headerName: str('Landing.order.table.finalAssortment'),
        flex: 1,
        minWidth: 160
      },
      {
        field: 'locations',
        headerName: str('Landing.order.table.location'),
        valueFormatter: ({ value }) =>
          `${value
            .slice(0, 3)
            .map((l: CustomerShipTo) => `${l.name} (${l.city})`)
            .join(', ')}${value.length > 3 ? ` (${value.length - 3} more)` : ''}`,
        flex: 2,
        minWidth: 160
      },
      {
        field: 'created',
        headerName: str('Landing.order.table.orderCreated'),
        valueFormatter: ({ value }) => format(value, 'MMM dd, yyyy'),
        initialSort: 'asc',
        flex: 1,
        minWidth: 140
      },
      {
        field: 'products',
        headerName: str('Landing.order.table.products'),
        valueFormatter: ({ value }) => value ?? '-',
        flex: 1,
        minWidth: 150
      },
      {
        field: 'units',
        headerName: str('Landing.order.table.units'),
        valueFormatter: ({ value }) => value ?? '-',
        flex: 1,
        minWidth: 120
      },
      {
        field: 'price',
        headerName: str('Landing.order.table.price'),
        valueFormatter: ({ value, data }) => formatPrice(data.currency, value) ?? '-',
        flex: 1,
        minWidth: 120
      },
      {
        field: 'status',
        headerName: str('Landing.order.table.status'),
        cellRenderer: ({ value }: { value: any }) => <StatusCapsule status={value} />,
        flex: 1,
        minWidth: 120
      },
      {
        field: 'noExport',
        headerName: '',
        cellRenderer: ({ data }: { data: any }) => (
          <ButtonsWrapper>
            <IAM action="orders.edit">
              <Button
                onClick={() => {
                  setCurrentOrder(data);
                  setOrderModalType('edit');
                }}
              >
                <Edit />
              </Button>
            </IAM>
            <IAM action="orders.edit">
              <Button
                onClick={() => {
                  setCurrentOrder(data);
                  setOrderModalType('duplicate');
                }}
              >
                <Copy />
              </Button>
            </IAM>
          </ButtonsWrapper>
        ),
        sortable: false,
        resizable: false,
        width: 80
      }
    ],
    [orders, selectedOrders, setSelectedOrders, setCurrentOrder, setOrderModalType]
  );

  const contextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): any => [
      {
        name: 'Edit',
        action: () => {
          setCurrentOrder(params?.node?.data);
          setOrderModalType('edit');
        }
      },
      {
        name: 'Duplicate',
        action: () => {
          setCurrentOrder(params?.node?.data);
          setOrderModalType('duplicate');
        }
      }
    ],
    [setCurrentOrder, setOrderModalType]
  );

  return (
    <AgListView
      data={orders}
      columns={columnDefs}
      exportColumns={columnDefs.map(c => c.field as string).filter(c => c !== 'noExport')}
      contextMenuItems={contextMenuItems}
    />
  );
};
