import { Wrapper } from 'components/Wrapper';
import { AppProviders, RouterDependentAppProviders } from 'providers';
import { StrictMode, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import { BASE_URL, useAuth } from 'utils';

import { awsRum, persistedStore } from '@yourxx/ui-utils';

import { Auth, Brands, brandsLoader, CommonLayout, GlobalLayout, Login, Logout, Terms } from './pages';
import { BrandLayout } from './pages/BrandLayout';
import { CustomerRoute } from './pages/Customer';
import { CustomersIndexRoute } from './pages/Customers';
import { LineProductsRoute, LinesIndexRoute, LinesRoute } from './pages/Lines';

awsRum?.enable();

const goToBrands = <Navigate to="/brands" replace />;

const App = () => {
  const { checkAuth } = useAuth();
  const useProfile = useMemo(() => persistedStore.getUserProfile(), []);
  const authenticatedRoutes =
    useProfile?.role === 'Customer' && !useProfile?.acceptedTermsAt ? (
      <>
        <Route path="/terms/*" element={<Terms />} />
        <Route path="*" element={<Navigate to="/terms" />} />
      </>
    ) : (
      <>
        <Route
          path="/brands"
          loader={brandsLoader}
          element={
            <Wrapper noHeader>
              <Brands />
            </Wrapper>
          }
        />

        {/* These redirects are to prevent 'auth' and 'login' to be treated as a brand. */}
        {/* TODO: We'll need to find a cleaner way of doing it, but let's just get the ball rolling for now. */}
        <Route path="logout" Component={Logout} />
        <Route path="auth/*">
          <Route index element={goToBrands} />
          <Route path="customers/*" element={goToBrands} />
          <Route path="lines/*" element={goToBrands} />
          <Route path="*" element={goToBrands} />
        </Route>
        <Route path="login/*">
          <Route index element={goToBrands} />
          <Route path="customers/*" element={goToBrands} />
          <Route path="lines/*" element={goToBrands} />
          <Route path="*" element={goToBrands} />
        </Route>
        <Route path="terms/*">
          <Route index element={goToBrands} />
          <Route path="customers/*" element={goToBrands} />
          <Route path="lines/*" element={goToBrands} />
          <Route path="*" element={goToBrands} />
        </Route>
        <Route Component={RouterDependentAppProviders}>
          <Route path=":brandName/*" Component={GlobalLayout}>
            <Route Component={BrandLayout}>
              <Route index element={<Navigate to="customers" replace />} />
              <Route path="*" element={<Navigate to="." replace />} />
              <Route Component={CommonLayout}>
                <Route path="customers/*">
                  <Route index Component={CustomersIndexRoute} />
                  <Route path=":customerId/*">
                    {/* We're defaulting to a value which represents a missing season value, as a placeholder, until */}
                    {/* data is loaded for the current customer. Afterward it will be replaced with the latest season. */}
                    {/* We could also request the current season from the API and default to that. */}
                    <Route index element={<Navigate to="season" replace />} />
                    <Route path=":season/*" Component={CustomerRoute} />
                  </Route>
                </Route>
                <Route path="lines/*">
                  {/* Same concept here, "season" is our null-season value until data is loaded. */}
                  <Route index element={<Navigate to="season" replace />} />
                  <Route path=":season/*" Component={LinesRoute}>
                    <Route index Component={LinesIndexRoute} />
                    <Route path=":lineId/*" Component={LineProductsRoute} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="*" element={goToBrands} />
      </>
    );

  const unauthenticatedRoutes = (
    <Route path="/" element={<Wrapper noHeader />}>
      <Route index element={<Navigate to="/login" replace />} />
      <Route path="login" Component={Login} />
      <Route path="logout" Component={Logout} />
      <Route path="auth" Component={Auth} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Route>
  );

  const router = createBrowserRouter(
    createRoutesFromElements(checkAuth() ? authenticatedRoutes : unauthenticatedRoutes),
    { basename: BASE_URL }
  );

  return <RouterProvider router={router} />;
};

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </StrictMode>
);
