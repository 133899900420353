export enum LocalStorageItems {
  idToken = 'id_token',
  accessToken = 'access_token',
  redirectUrl = 'redirect_url',
  refreshToken = 'refresh_token',
  tokenExpiry = 'token_expiry',
  permissions = 'user_permissions',
  userProfile = 'user_profile',
  autoLogin = 'auto_login',
  returnUrl = 'return_url'
}

export enum V2Routes {
  CanvasBoard = '/:type/:id/v2/canvas/board/:boardId',
  CanvasLanding = '/:type/:id/v2/canvas/board',
  CanvasNewBoard = '/:type/:id/v2/canvas/board/new',
  CanvasShare = '/v2/canvas/share/:shareUrl',
  Insights = '/v2/insights/:id'
}

export enum FeatureFlags {
  AllowSwitchingToV1 = 'AllowSwitchingToV1',
  AllowSwitchingToV2 = 'AllowSwitchingToV2',
  AutoAdoptV2 = 'AutoAdoptV2',
  MyCanvas = 'MyCanvas',
  Localisation = 'Localisation',
  InsightsIcon = 'InsightsIcon',
  InsightsDev = 'InsightsDeveloper',
  DropRemoveProducts = 'DropRemoveProducts',
  InsightStorySets = 'InsightStorySets',
  InsightsSuggestions = 'InsightsSuggestions',
  InsightsRecommendations = 'InsightsRecommendations',
  AssortmentFinalisation = 'AssortmentFinalisation',
  InsightsUpdateStorySets = 'InsightsUpdateStorySets',
  EditLineVLPOrder = 'EditLineVLPOrder',
  EditAssortmentVLPOrder = 'EditAssortmentVLPOrder',
  EnhancedSizeGrids = 'EnhancedSizeGrids',
  ExportSizeGrids = 'ExportSizeGrids',

  Admin = 'AdminIcon',
  Admin_Manage = 'Admin-Manage',
  AdminUsers = 'AdminUsers',
  AdminCustomers = 'AdminCustomers',
  AdminCustomers_Archive = 'AdminCustomers-Archive',
  AdminCustomers_Create = 'AdminCustomers-Create',
  AdminCustomers_ViewArchived = 'AdminCustomers-ViewArchived',
  AdminCustomerUsers = 'AdminCustomerUsers',
  AdminLines = 'AdminLines',
  AdminAssortments = 'AdminAssortments',
  AdminReports = 'AdminReports',
  AdminLocalisation = 'AdminLocalisation',
  AdminIAM = 'AdminIAM',
  AdminSAP = 'AdminSAP',
  AdminStorySets = 'AdminStorySets',

  Assortment_ChangeDisplaySettings = 'Assortment-changeDisplaySettings',
  Assortment_Create = 'Assortment-create',
  Assortment_Copy = 'Assortment-copy',
  Assortment_DownloadCsv = 'Assortment-downloadCSV',
  Assortment_DownloadPdf = 'Assortment-downloadPDF',
  Assortment_EditTitle = 'Assortment-editTitle',
  Assortment_Manage = 'Assortment-manage',
  Assortment_SelectItems = 'Assortment-selectItems',
  Assortment_ViewArchived = 'Assortment-viewArchived',
  Assortment_ViewAtOnce = 'Assortment-viewAtOnce',
  Assortment_ViewCollection = 'Assortment-viewCollection',
  Assortment_ViewOptionCountTargets = 'Assortment-viewOptionCountTargets',
  Assortment_ViewParentCollection = 'Assortment-viewParentCollection',
  Assortment_Publish = 'Assortment-publish',
  Assortment_SelectItemsProductAddToStory = 'Assortment-selectItemsProductAddToStory',
  Assortment_SelectItemsProductEdit = 'Assortment-selectItemsProductEdit',
  Assortment_SelectItemsProductRemove = 'Assortment-selectItemsProductRemove',
  Assortment_SelectItemsProductReplace = 'Assortment-selectItemsProductReplace',
  Assortment_ViewLastUpdated = 'Assortment-viewLastUpdated',
  Assortment_ViewRevenueTable = 'Assortment-viewRevenueTable',
  Assortment_ViewUnpublished = 'Assortment-viewUnpublished',
  Assortment_ViewParentLineName = 'Assortment-viewParentLineName',

  Filters_FilterDistribution = 'Filters-filterDistribution',
  Filters_FilterNotInAssortment = 'Filters-filterNotInAssortment',
  Filters_FilterPricePositioning = 'Filters-filterPricePositioning',
  Filters_FilterSeasonality = 'Filters-filterSeasonality',
  Filters_FilterStory = 'Filters-filterStory',
  Filters_SaveFilterCombinations = 'Filters-saveFilterCombinations',
  Filters_ViewFilterCombinations = 'Filters-viewFilterCombinations',

  LineCollection_Publish = 'LineCollection-publish',
  LineCollection_SelectItemsProductAddToStory = 'LineCollection-selectItemsProductAddToStory',
  LineCollection_SelectItemsProductEdit = 'LineCollection-selectItemsProductEdit',
  LineCollection_SelectItemsProductRemove = 'LineCollection-selectItemsProductRemove',
  LineCollection_SelectItemsProductReplace = 'LineCollection-selectItemsProductReplace',
  LineCollection_View = 'LineCollection-view',

  LineTree_View = 'LineTree-view',
  LineTree_ViewAdvanced = 'LineTree-viewAdvanced',
  LineTree_ViewGlobalLine = 'LineTree-viewGlobalLine',
  LineTree_ViewArchived = 'LineTree-viewArchived',

  Product_EditForecast = 'Product-editForecast',
  Product_EditMandatory = 'Product-editMandatory',
  Product_EditNote = 'Product-editNote',
  Product_EditPhasing = 'Product-editPhasing',
  Product_EditRanking = 'Product-editRanking',
  Product_EditVlpOrder_Line = 'Product-editVlpOrder-Line',
  Product_EditVlpOrder_Assortment = 'Product-editVlpOrder-Assortment',
  Product_SelectVideo = 'Product-selectVideo',
  Product_ViewDeliveries = 'Product-viewDeliveries',
  Product_ViewForecast = 'Product-viewForecast',
  Product_ViewNote = 'Product-viewNote',
  Product_ViewPhasing = 'Product-viewPhasing',
  Product_ViewPricePositioning = 'Product-viewPricePositioning',
  Product_ViewRanking = 'Product-viewRanking',
  Product_ViewReplacerFinish = 'Product-viewReplacerFinish',

  Stories_Update = 'Stories-update',

  SizeGrids_PrimaryConso = 'SizeGrids-primaryConso',
  SizeGrids_PrimaryPlanningGroup = 'SizeGrids-primaryPlanningGroup',
  SizeGrids_MergedGrids = 'SizeGrids-mergedGrids',
  SizeGrids_ViewConso = 'SizeGrids-viewConso',
  SizeGrids_ViewPlanningGroup = 'SizeGrids-viewPlanningGroup'
}
